import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#121212"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9826 5.65949L6.49739 8.82974V15.1703L11.9826 18.3405L17.4679 15.1703V8.82974L11.9826 5.65949ZM12.213 4.06179C12.0705 3.9794 11.8948 3.9794 11.7523 4.06179L5.23035 7.83118C5.0878 7.91358 5 8.06585 5 8.23062V15.7694C5 15.9342 5.0878 16.0864 5.23035 16.1688L11.7523 19.9382C11.8948 20.0206 12.0705 20.0206 12.213 19.9382L18.7349 16.1688C18.8775 16.0864 18.9653 15.9342 18.9653 15.7694V8.23062C18.9653 8.06585 18.8775 7.91358 18.7349 7.83118L12.213 4.06179Z"
        fill="#FF9211"
      />
      <path
        d="M11.9825 7.95604L8.48407 9.97801V14.022L11.9595 16.0306C11.9738 16.0389 11.9825 16.0541 11.9825 16.0706V16.7629C11.9825 16.7984 11.9442 16.8206 11.9135 16.8029L7.96576 14.5212C7.85885 14.4595 7.79297 14.3453 7.79297 14.2217V9.77829C7.79297 9.65471 7.85885 9.54052 7.96576 9.47871L11.8098 7.25702C11.9167 7.19525 12.0484 7.19525 12.1553 7.25702L16.1188 9.53947C16.1493 9.55701 16.1496 9.60092 16.1193 9.61889C15.8854 9.75777 15.7657 9.81954 15.504 9.96523C15.4898 9.97311 15.4725 9.97304 15.4584 9.96494L11.9825 7.95604Z"
        fill="#FF9211"
      />
      <path
        d="M12.5588 12.0853C12.5588 12.0682 12.5682 12.0526 12.5832 12.0446L16.0618 10.1911C16.0925 10.1748 16.1295 10.197 16.1295 10.2319V14.7407C16.1295 14.7572 16.1208 14.7723 16.1066 14.7806L12.628 16.8025C12.5973 16.8204 12.5588 16.7982 12.5588 16.7626V12.0853Z"
        fill="#FF9211"
      />
    </svg>
  );
}

export default SvgComponent;
