// tslint:disable:object-literal-sort-keys
const ko = {
  test: "테스트",
  developmentBannerAlert:
    "이 대시보드는 개발 환경에서 동작하며, 테스트넷, 메인넷 대시보드와는 별도의 계정을 사용합니다.",
  testnetBannerAlert:
    "이 대시보드는 테스트넷에서 동작하며, 메인넷 대시보드와는 별도의 계정을 사용합니다.",
  expectedFeeAmount: "예상 수수료",
  amount: "수량",
  fromAddress: "보내는 주소",
  receivedAddress: "받는 주소",
  withdrawMethod: "출금 방법",
  loginLabel: "로그인 정보",
  time: "시간",
  utc: "(UTC+9)",
  requester: "요청자",
  wallet: "지갑",
  withdrawWallet: "출금 지갑",
  targetWallet: "해당 지갑",
  addressName: "주소명",
  address: "주소",
  withdrawalAddress: "출금 주소",
  coin: "코인",
  coinToken: "코인/토큰",
  coinTokenName: "코인/토큰명",
  coinName: "코인명",
  allCoinAndToken: "모든 코인/토큰",
  username: "계정",
  ipAddress: "IP주소",
  ipAddress1: "IP 주소",
  registeredIpAddress: "등록한 IP주소",
  browser: "브라우저",
  location: "접속 위치",
  confirmDashbord: "대시보드로 확인하러 가기",
  masterWallet: "마스터 지갑",
  userWallet: "사용자 지갑",
  withdrawal: "출금",
  deposit: "입금",
  next: "다음",
  password: "비밀번호",
  confirm: "확인",
  name: "이름",
  ifHelp: "도움이 필요하시다면",
  contact: "로 연락해 주세요.",
  copyMessage: "가 복사되었습니다.",
  authButton: "인증하기",
  transactionHash: "트랜잭션 해시",
  deposit1: "입금하기",
  status: "상태",
  warningWithdrawalLimit1:
    "1일 출금 한도는 한국 시간 기준 00:00시에 초기화 됩니다.",
  warningWIthdrawalLimit2:
    "‘출금 가능’ 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
  copyWalletAddressMessage: "의 지갑 주소가 복사되었습니다.",
  reissue: "재발급하기",
  depositAddress: "입금 주소",
  activate: "활성화하기",
  withdrawalLimit: "출금 한도",
  gasFeeWalletAddressCopy: "수수료 지갑 주소 복사",
  oldPassword: "기존 비밀번호",
  newPasswordInput: "새로운 비밀번호 입력",
  newPasswordConfirmInput: "새로운 비밀번호 재입력",
  verifyNewAndNewConfirmDiff:
    "입력한 비밀번호가 서로 일치하지 않습니다. 다시 확인해주세요.",
  create: "생성하기",
  cancel: "취소하기",
  approve: "결재하기",
  reject: "반려하기",
  depositAddressCopyMessage: "입금 주소가 클립보드에 복사되었습니다.",
  previousStep: "이전 단계로",
  content: "내역",
  addIpAddress: "IP 주소 추가하기",
  select: "선택하세요",
  qrCode: "QR코드",
  depositWarning: "입금 주의사항",
  withdraw: "출금하기",
  failedCreateWallet: "지갑 생성 실패",
  ifAbnormalAct: "비정상적인 활동이라면",
  delete: "삭제하기",
  edit: "수정하기",
  contactEmail: "이메일로 연락주시면 도와드리겠습니다.",
  close: "닫기",
  createMasterWallet: "마스터 지갑 생성",
  forgottenOTP: "OTP를 분실하셨나요?",
  forgottenPassword: "비밀번호를 잊어버리셨나요?",
  requestWithdraw: "출금 요청",
  withdrawAddressWhitelist: "출금 주소 화이트리스팅",
  inactiveOnlyAdmin: "관리자만 비활성화할 수 있습니다.",
  activeOnlyAdmin: "관리자만 활성화할 수 있습니다.",
  searchNameAndSymbol: "이름 / 심볼로 검색",
  emailAddress: "이메일 주소",
  email: "이메일",
  save: "저장하기",
  newPassword: "새로운 비밀번호",
  verifyWrongPassword: "비밀번호를 잘못 입력하였습니다.",
  apiSync: "API 연동",
  searchUserAndIDAndAddress: "계정 / ID / 주소로 검색",
  verifyDuplicateLabel: "중복되는 이름을 설정할 수 없습니다.",
  security: "보안",
  walletName: "지갑 이름",
  walletPassword: "지갑 비밀번호",
  setNewWalletPasswordMessage: "새로운 지갑 비밀번호가 설정 되었습니다.",
  mismatchWalletPassword: "계정 비밀번호가 일치하지 않습니다.",
  retryGasPriceCompleteMessage: "실시간 적정 가스비로 다시 전송되었습니다.",
  setRecoverkitInfo: "복구 키트 정보 입력하기",
  setPassword: "비밀번호 설정하기",
  setNewPassword: "새로운 비밀번호 설정하기",
  setOTP: "OTP 입력하기",
  setName: "이름 설정하기",
  setWalletPassword: "지갑 비밀번호 설정하기",
  cannotRecoverWallet: "지갑 복구가 불가능",
  createMasterWallet1: "마스터 지갑 생성하기",
  total: "총",
  count: "개",
  oneDaysWithdrawalLimit: "1일 출금 한도",
  oneTimeWithdrawalLimit: "1회 출금 한도",
  ID: "ID",
  creating: "생성중",
  createUserWallet: "사용자 지갑 추가하기",
  createDepositAddress: "입금 주소 추가하기",
  authority: "권한",
  lessThanWithralLimit: "한도 이하의 출금",
  delete1: "삭제",
  addAddress: "주소 추가하기",
  teamManager: "팀 관리",
  sixDigits: "6자리 숫자",
  directInput: "직접 입력",
  sendingCoin: "보낼 코인",
  willWithdrawAmount: "출금 가능",
  requestWithdrawMessage:
    "마지막 결재 이후 확정된 잔액입니다. 처리 대기, 또는 처리 중인 수량은 포함하지 않습니다.",
  withdrawAmount: "출금 수량",
  minimalExpectedAmount: "최소 예치 금액",
  minimalExpectedAmountMessage:
    "수수료가 실시간으로 변할 수 있으므로\n최소 예치 금액 이상 입금하셔야 합니다.",
  myGasFee: "보유한 수수료",
  gasFeePayment: "수수료 대납 이벤트 적용",
  depositCoinAndToken: "지갑에 코인/토큰을 입금하세요",
  chargeGasFee: "가스비 충전하기",
  inactiveWhitelist: "화이트리스팅이 비활성화되어 있습니다.",
  ifAbnormalAct1: "비정상적인 시도라면",
  reissueAccessToken: "대시보드에서 해당 사용자의 Access Token을 재발급하세요.",
  withdrawInfo: "출금 정보",
  ifAbnormalAct2: "비정상적인 출금 시도라면",
  completeActiveWhitelist: "화이트리스팅 활성화 완료",
  isActiveWhitelisting: "화이트리스팅을 활성화하시겠습니까?",
  completeInactiveWhitelist: "화이트리스팅 비활성화 완료",
  isInactiveWhitelisting: "화이트리스팅을 비활성화하시겠습니까?",
  inactive: "비활성화하기",
  emptyCoin: "코인이 없습니다.",
  empty: "없음",
  availableCurrentRegisteredIp:
    "지금부터 등록된 IP 주소로만 API 호출이 가능합니다.",
  change: "변경하기",
  retryGasFeeMessage:
    "이더리움 실시간 적정 가스비가 급등하여 채굴이 지연되고 있습니다. 5분 이내에 채굴되기를 원하신다면 실시간 적정 가스비로 다시 전송하세요.",
  retryRealGasFee: "실시간 가스비로 다시 전송하기",
  gasPrice: "가스비 (Gas Price)",
  fee: "수수료",
  retryRealGasFeeMessage: "실시간 적정 가스비로 다시 전송합니다",
  newTransactionMessage: "새로운 트랜잭션이 발생합니다.",
  realGasFee: "실시간 적정 가스비",
  realNetworkBasedGasFee:
    "실시간 네트워크 기준으로 5분 이내에 트랜잭션이 채굴될 수 있는 가스비",
  doNotBeSurprised: "놀라지 마세요!",
  basedTransactionMessage:
    "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
  sameTransactionMessage:
    "동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지\n트랜잭션은 취소됩니다.",
  dealDuplicateConfirmMessage:
    "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
  retryCancel: "재전송 취소",
  retry: "재전송하기",
  searchTransactionHash: "트랜잭션 해시로 검색할 수 있습니다.",
  setPassword1: "의 비밀번호를 입력하세요.",
  masterWalletUnderUserWalletMessage:
    "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
  applyEachUserWalletMessage: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
  withdrawalLimitMessage:
    "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
  warningConfirm1: "번의 컨펌 발생 후 지갑에 반영됩니다.",
  warningConfirm2: "반드시 입금 주소를 확인 후 전송하시기 바랍니다.",
  warningConfirm3: "가스비는 해당 지갑이 아닌 가스비 지갑에 입금하셔야 합니다.",
  warningConfirm4: "만 입금 가능합니다.",
  request: "요청",
  approvalPolicy: "결재선",
  approvalStatus: "결재 상태",
  custodyStatus: "처리 상태",
  member: "구성원",
  invite: "초대하기",
  caution: "주의사항",
  invited: "초대됨",
  approvalMemoPlaceholder: "결재 사유를 작성하세요. (280자 제한)",
  memo: "메모",
  walletCreation: "지갑 생성",
  registration: "등록",
  allRequestType: "모든 유형",
  skip: "건너뛰기",
  walletAddress: "지갑 주소",
  registerCoin: "코인 등록하기",
  blockchain: "블록체인",
  registeredAt: "등록 시간",
  registerer: "등록자",
  register: "등록하기",
  symbol: "심볼",
  allWallets: "모든 지갑",
  balance: "잔고",
  max: "최대",
  addButton: "추가하기",
  contactSupport: "고객센터로 연락하기",
  language: "언어",
  dontShowAgain: "다시 보지 않기",
  libs: {
    message: {
      VERIFIED_IP: "IP주소가 인증되었습니다. 로그인을 진행해주세요.",
    },
    error: {
      UNKOWN_ERROR: "알 수 없는 에러가 발생하였습니다. 다시 시도해주세요",
      WRONG_WALLET_NAME: "올바르지 않은 지갑 이름입니다.",
      EXISTS_WALLET_NAME:
        "다른 마스터 지갑과 중복되는 이름을 설정할 수 없습니다.",
      WRONG_ADDRESS: "올바른 주소를 입력해주세요.",
      LACK_BALANCE_AMOUNT: "잔액이 부족합니다.",
      NETWORK_ERROR: "알 수 없는 에러가 발생하였습니다. 다시 시도해주세요",
      WRONG_RECOVERKIT_INPUT: "데이터가 일치하지 않습니다.",
      ACCESS_GUEST: "잘못된 접근",
      FORBIDDEN_ERROR_HOUR:
        "로그인 후 30분이 지나 보안을 위해 자동 로그아웃 되었습니다.\n다시 로그인 해주세요.",
      GASFEE_INSUFFICIENT_FUNDS_CREATE_WALLET:
        "가스비가 부족해 지갑 생성에 실패했습니다.",
      GASFEE_INSUFFICIENT_WITHDRAW_WALLET: "가스비 지갑 잔액이 부족합니다.",
      EMPTY_ALLOWED_ADDRESS_NAME: "이름을 입력해주세요.",
      EXISTS_ALLOWED_ADDRESS: "이미 등록된 주소입니다.",
      ALREADY_EXISTS_ALLOWED_ADDRESS_NAME:
        "다른 주소와 중복되는 이름을 설정할 수 없습니다.",
      SAME_GAS_PRICE: "이미 동일한 가스비로 재전송 되었습니다.",
      UTXO_LOCKED:
        "앞선 트랜잭션 전송이 완료되지 않아 출금에 실패하였습니다. 앞선 트랜잭션 전송을 완료하시거나, 40초 후에 재시도하세요.",
      UNAUTHORIZED:
        "로그인 후 30분이 지나 보안을 위해 자동 로그아웃 되었습니다.\n다시 로그인 해주세요.",
      EMAIL_DOES_NOT_EXISTS: "존재하지 않는 이메일입니다.",
      INVALID_PASSWORD: "비밀번호를 잘못 입력하였습니다.",
      OTP_AUTHENTICATION_FAILED: "인증 코드를 잘못 입력하셨습니다.",
      NOT_VERIFIED_IP:
        "새로운 IP주소에서 로그인을 시도하였습니다.\n%email%으로 전송된 메일을 확인하시고 해당 IP 주소를 인증해주세요.",
      TIMEOUT_IP:
        "24시간이 지나 인증번호의 유효기간이 만료되었습니다.\n새로운 인증 메일을 받으시려면 다시 로그인해주세요.",
      ALREADY_VERIFIED_IP: "이미 확인되었습니다.",
      INVALID_IP_VERIFY_REQUEST:
        "로그인을 시도했던 IP주소와 다릅니다.\n동일한 장소와 네트워크 환경에서 인증해주세요.\n만약 동일한 문제가 반복된다면 고객센터로 연락해주세요.",
      FAIL_TO_REPLACE_TRANSACTION:
        "실시간 적정 가스비가 해당 트랜잭션의 가스비보다 낮아 재전송하지 않습니다.",
      ALREADY_PROCESSED_TRANSACTION: "이미 채굴된 트랜잭션입니다.",
      ALREADY_REPLACED_TRANSACTION: "이미 교체된 트랜잭션입니다.",
      INVALID_MINIMUM_BALANCE: "가스비가 부족해 지갑 생성에 실패했습니다.",
      ALREADY_USED_PASSPHRASE:
        "최근에 사용한 비밀번호입니다. 다른 비밀번호를 사용하세요.",
      ALREADY_PROCESSING_CSV: "CSV를 생성중입니다. 잠시 후 다시 클릭하세요.",
      TRY_TO_PROCESS_CSV:
        "CSV를 생성합니다. 5분 뒤에 버튼을 다시 클릭해주세요.",
      ALREADY_AUTHENTICATED_OTP:
        "이미 사용된 OTP 코드입니다. {{time}}초 뒤에 재시도해주세요.",
      SAME_EMAIL_INVALID_PASSWORD:
        "계정의 이메일 주소와 동일합니다. 다른 비밀번호로 설정해주세요.",
      PASSPHRASE_MAX_ATTEMPT:
        "비밀번호를 5회 잘못 입력하셨습니다. 로그인이 차단됩니다. [비밀번호를 잊어버리셨나요?] 버튼을 클릭해 비밀번호를 다시 설정하세요.",
      SAME_PREVIOUS_PASSWORD_INVALID_PASSWORD:
        "기존의 비밀번호와 동일합니다. 다른 비밀번호로 설정해주세요.",
      EXPIRED_PASSWORD: "기한 만료된 비밀번호입니다.",
      EXPIRED_TOKEN: "링크 유효기간이 만료되었습니다",
      EXPIRED_ACTION:
        "일정시간 동안 사이트 이용이 없어 자동 로그아웃 되었습니다.",
      NOT_COMPLETED_CUSTODY_REQUEST_EXISTS:
        "처리 중인 요청을 모두 처리해야 새로운 요청을 처리할 수 있습니다.",
      NOT_IDENTICAL_SIGNER: "이미 다른 매니저가 서명을 진행하고 있습니다.",
      ALREADY_CREATED_ADDRESS_BOOK: "이미 등록 결재 요청된 주소명/주소입니다.",
      EXPIRED_INVITATION:
        "초대한 지 24시간이 지나 초대 메일이 만료되었습니다. \n관리자에게 초대 메일 재전송을 요청하세요.",
      INVALID_INVITATION: "새로 도착한 이메일을 확인하세요.",
      CANCELED_INVITATION:
        "관리자가 초대를 취소하였습니다. \n관리자에게 초대 메일 재전송을 요청하세요.",
      ALREADY_SIGNED_UP_ACCOUNT: "이미 가입이 완료된 메일입니다.",
      DUPLICATED_ADDRESS_BOOK_DELETE_REQUEST:
        "이미 삭제 결재 요청된 주소록입니다.",
      DUPLICATED_ADDRESS_BOOK_ADDRESS: "이미 등록 결재 요청된 주소록입니다.",
      DUPLICATED_ADDRESS_BOOK_NAME: "이미 해당 주소록이 삭제되었습니다.",
      DUPLICATED_EMAIL: "이미 구성원으로 등록되어 있습니다.",
      ALREADY_INVITED_ACCOUNT_BY_DIFFERENT_ORGANIZATION:
        "다른 조직에 속한 계정입니다.",
      SESSION_TIME_OUT: "다른 브라우저에서 로그인해 로그아웃 되었습니다.",
      INVALID_TRANSACTION: "처리할 수 없는 출금 요청입니다.",
      ALREADY_EXISTING_COIN: "이미 등록된 코인입니다.",
      INVALID_QRCODE: "유효하지 않은 QR 코드입니다.",
      INVALID_ORDER_QRCODE:
        "QR 코드의 순서가 올바르지 않습니다. QR 코드에 적힌 순서대로 인식해주세요.",
      INVALID_COMPONENT_QRCODE: "콜드월렛에서 발급한 QR 코드를 인식하세요.",
    },
  },
  utils: {
    blockchain: {
      getBlockchainItemBy: {
        bitcoin: "비트코인",
        bitcoin_deprecated: "비트코인_구버전",
        ethereum: "이더리움",
        ethereum_deprecated: "이더리움_구버전",
        kaia: "카이아",
        kaia_deprecated: "카이아_구버전",
        polygon: "폴리곤",
        polygon_deprecated: "폴리곤_구버전",
        amoy: "폴리곤",
        overProtocol: "오버프로토콜",
        bsc: "바이낸스 스마트 체인",
        wemix: "위믹스",
        xpla: "엑스플라",
        bizauto: "비즈오토",
        arbitrumOne: "아비트럼 원",
        storyTestnet: "스토리",
        story: "스토리",
        coreTestnet: "코어",
        core: "코어",
        baseSepolia: "베이스",
        base: "베이스",
        solanaDevnet: "솔라나",
        solana: "솔라나",
      },
      makeDepositWarnings: {
        ethWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
          line3: "$t(warningConfirm3)",
        },
        kaiaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        polygonWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        overWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bscWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        wemixWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        xplaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bizautoWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        arbitrumOneWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        storyTestnetWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        storyWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        coreTestnetWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        coreWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        baseSepoliaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        baseWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        solanaWarning: {
          line1: "$t(warningConfirm2)",
          line2: "$t(warningConfirm3)",
        },
        bitcoinWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
          line2: "$t(warningConfirm2)",
        },
        common: {
          token: " 토큰",
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
        },
      },
      makeGasFeeDepositFormWarnings: {
        ethWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        kaiaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        polygonWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        overWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        bscWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        wemixWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        xplaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        bizautoWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        arbitrumOneWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        storyTestnetWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        storyWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        coreTestnetWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        coreWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        baseSepoliaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        baseWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        solanaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm1)",
        },
        common: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2:
            "해당 주소는 마스터 지갑 주소와는 다른 별도의 지갑 주소입니다.",
        },
      },
      makeGasFeeDepositWarnings: {
        ethWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2Prefix: "",
          line2Postfix: "$t(warningConfirm1)",
          line3: "$t(warningConfirm2)",
        },
        kaiaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        polygonWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        overWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bscWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        wemixWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        xplaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        bizautoWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        arbitrumOneWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        storyTestnetWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        storyWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        coreTestnetWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        coreWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        baseSepoliaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        baseWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
        solanaWarning: {
          line1Prefix: "",
          line1Postfix: "$t(warningConfirm4)",
          line2: "$t(warningConfirm2)",
        },
      },
      walletNameByBlockchain: {
        masterWallet: "$t(masterWallet)",
        userWallet: "$t(userWallet)",
        wallet: "$t(wallet)",
      },
    },
    number: {
      getOverFloatingAndNotNumber: {
        regexMessage1: "소수점 ",
        regexMessage2: "자리까지만 입력할 수 있습니다.",
        regexMessage3: "숫자를 입력해주세요.",
      },
    },
  },
  stores: {
    model: {
      user: {
        transformRoleName: {
          admin: "관리자",
          spender: "출금 가능",
          viewer: "조회 가능",
          haechi: "해치랩스",
        },
      },
    },
    balances: {
      selectedAllBalanceName: "$t(allCoinAndToken)",
    },
    gasFeeUsages: {
      contentByTransactionType: {
        withdrawal: "$t(withdrawal)",
        deposit: "$t(deposit)",
        unknownExternalCall: "알 수 없는 외부 호출",
        smartContractCall: "스마트컨트랙트 호출",
        masterWalletDeployment: "$t(createMasterWallet)",
        userWalletDeployment: "사용자 지갑 생성",
      },
    },
    recoverWallet: {
      verifyPassphaseDiff: "데이터가 일치하지 않습니다.",
    },
    withdrawPolicies: {
      policyDescription: {
        and: " 외 ",
        count: "개",
      },
    },
    organization: {
      error: "선택한 org가 없습니다.",
    },
  },
  pages: {
    "401": {
      title: "접근할 수 없는 페이지입니다.",
      description: "관리자만 해당 페이지를 확인할 수 있습니다.",
    },
    "404": {
      title: "페이지를 찾을 수 없습니다.",
      description: "에러가 계속 된다면 아래 이메일로 연락주세요.",
    },
    account: {
      otp: {
        guide: {
          title: "OTP 설정하기",
          description:
            "최초 로그인시 OTP 설정이 필요합니다. \nGoogle Authenticator에서 QR코드를 스캔해 주세요.",
          androidDownloadButton: "안드로이드폰 다운로드",
          iosDownloadButton: "아이폰 다운로드",
          nextButton: "$t(next)",
        },
        register: {
          submitButton: "$t(authButton)",
          forgottenOtp: "$t(forgottenOTP)",
        },
      },
      login: {
        title: "로그인하기",
        emailLabel: "이메일",
        passwordLabel: "$t(password)",
        forgottenPassword: "$t(forgottenPassword)",
        loginButton: "로그인",
      },
    },
    dashboard: {
      balanceReport: {
        title: {
          description1: "안녕하세요, ",
          description2: "님",
        },
        empty: {
          title: "지갑이 없습니다.",
          description: "생성된 또는 소속된 지갑이 없습니다.",
        },
        createWallet: "지갑 생성하기",
      },
      operation: {
        authority: {
          appbarLeftCaption: "권한 관리",
          title: "더 쉽고 안전하게 협업하세요.",
          description:
            "관리자는 팀원의 역할을 설정할 수 있습니다.\n각 팀원에게 알맞은 역할과 권한을 부여하세요.",
          authorityInfoButton: "각 역할은 어떤 권한을 가지나요?",
        },
      },
      request: {
        withdraw: {
          appbarLeftCaption: "$t(requestWithdraw)",
          title: "출금 요청을 확인하세요.",
          description:
            "한도를 초과한 출금은 관리자의 승인이 필요합니다.\n요청을 확인하고 출금을 승인하세요.",
          withdrawApprovalCompleteTitle: "출금 승인 완료",
          withdrawApprovalCompleteSubmitButton: "$t(confirm)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        inbox: {
          title: "받은 요청함",
        },
        outbox: {
          title: "보낸 요청함",
        },
        expiredPassword: {
          title: "새로운 비밀번호 설정",
          step1: "새로운 비밀번호 입력",
          step2: "OTP 인증",
          step3: "변경 완료",
        },
      },
      wallet: {
        "[blockchain]": {
          "[id]": {
            user: {
              "[userWalletId]": {
                userAddressCopyMessage: "사용자 지갑 주소 복사하기",
                copyAddressCompleteMessage: "$t(copyMessage)",
              },
            },
            allowedAddress: {
              title: "미리 허용한 주소로만 안전하게 출금하세요.",
              description:
                "화이트리스팅을 활성화하면 허용되지 않은 주소로의 출금은 차단됩니다.\n출금을 허용할 주소를 등록하세요.",
              contentTitle: "$t(withdrawAddressWhitelist)",
              whitelistInactivated: "$t(inactiveOnlyAdmin)",
              whitelistActivated: "$t(activeOnlyAdmin)",
              canNotLoadMasterWallet: "마스터 지갑을 불러오지 못하였습니다.",
            },
            index: {
              walletAddressCopyMessage: "지갑 주소 복사하기",
              copyAddressCompleteMessage: "$t(copyMessage)",
            },
            policy: {
              title: "$t(withdrawalLimit)",
              description: "코인/토큰별 출금 한도를 설정할 수 있습니다.",
              policyInfoButton: "한도가 어떻게 적용되나요?",
              searchPlaceholder: "$t(searchNameAndSymbol)",
            },
          },
          gasFee: {
            gasFeeName: "수수료 지갑",
            gasFeePayment: "수수료 이벤트 적용",
            gasFeeAddressCopyMessage: "$t(gasFeeWalletAddressCopy)",
            copyAddressCompleteMessage: "$t(copyMessage)",
          },
        },
      },
      account: {
        appbarLeftCaption: "계정 정보",
        usernameLabel: "사용자 이름",
        firstName: "성",
        lastName: "$t(name)",
        emailLabel: "$t(emailAddress)",
        saveButton: "$t(save)",
        passwordLabel: "$t(password)",
        oldPasswordLabel: "$t(oldPassword)",
        oldPasswordPlaceholder: "$t(oldPassword)",
        newPasswordLabel: "$t(newPassword)",
        newPasswordPlaceholder: "$t(newPasswordInput)",
        newPasswordConfirmPlaceholder: "$t(newPasswordConfirmInput)",
        forgottenPassword: "비밀번호를 잊어 버리셨나요?",
        otpButton: "$t(authButton)",
        closeButton: "$t(close)",
        verifyUsernameRule: "영문, 혹은 한글만 입력 가능합니다.",
        verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        verifyWrongPassword: "$t(verifyWrongPassword)",
      },
    },
  },
  organizations: {
    dashboard: {
      account: {
        tab: {
          account: {},
          locale: {
            languageLabel: "언어",
            timezoneLabel: "타임존",
            saveButton: "$t(save)",
          },
        },
      },
      tab: {
        gasFee: {
          deposit: {
            depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
          },
          usage: {
            searchPlaceholder: "트랜잭션 해시 검색",
          },
        },
        balance: {
          searchPlaceholder: "$t(searchNameAndSymbol)",
        },
        deposit: {
          title: "$t(deposit1)",
          description1: "을 ",
          description2: "를 ",
          description3: "입금할 수 있습니다.",
          depositAddressCopyMessage: "$t(depositAddressCopyMessage)",
        },
        depositAddress: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          verifyTargetEthWallet: "해당 지갑은 이더지갑입니다.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        policy: {
          title: "$t(security)",
          description:
            "지갑별 보안 정책으로 자산을 안전하게 관리할 수 있습니다.",
          policyLabel: "$t(withdrawalLimit)",
          allowedAddressLabel: "$t(withdrawAddressWhitelist)",
          active: "활성화",
          inactive: "비활성화",
        },
        setting: {
          walletName: "$t(walletName)",
          walletId: "지갑 ID",
          saveButton: "$t(save)",
          walletPassword: "$t(walletPassword)",
          oldPassword: "$t(oldPassword)",
          newPassword: "$t(newPassword)",
          newPasswordInput: "$t(newPasswordInput)",
          newPasswordInputConfirm: "$t(newPasswordConfirmInput)",
          forgottenPassword: "지갑 비밀번호를 잊어 버리셨나요?",
          walletNameCompleteTitle: "지갑 이름 변경 완료",
          walletNameCompleteDescription: "새로운 지갑 이름이 설정 되었습니다.",
          walletPasswordCompleteTitle: "지갑 비밀번호 변경 완료",
          walletPasswordCompleteDescription: "$t(setNewWalletPasswordMessage)",
          forgottenPasswordCompleteTitle: "지갑 비밀번호 복구 완료",
          forgottenPasswordCompleteDescription:
            "$t(setNewWalletPasswordMessage)",
          authButton: "$t(authButton)",
          closeButton: "$t(close)",
          verifyDuplicateWalletName: "중복된 지갑 이름이 있습니다.",
          verifyWrongPassword: "$t(verifyWrongPassword)",
          verifyOldAndNewSame:
            "새로운 비밀번호가 기존 비밀번호와 동일합니다. 다른 비밀번호를 입력해주세요.",
          verifyNewAndNewConfirmDiff: "$t(verifyNewAndNewConfirmDiff)",
        },
        smartcontract: {
          searchPlaceholder: "스마트컨트랙트 주소 / 트랜잭션 해시 검색",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        transfer: {
          coinAndTokenEmptyLabel: "출금 가능한 코인/토큰이 없습니다.",
          searchPlaceholder: "내역 / 트랜잭션 해시 검색",
          retryGasPriceCompleteMessage: "$t(retryGasPriceCompleteMessage)",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
        user: {
          searchPlaceholder: "$t(searchUserAndIDAndAddress)",
          btcWalletMessage: "해당 지갑은 btc지갑입니다.",
          verifyDuplicateLabel: "$t(verifyDuplicateLabel)",
        },
        withdraw: {
          isNotRegisterWhitelistAddress:
            "화이트리스팅에 등록된 주소가 아닙니다.",
          notExistsCoin: "코인이 존재하지 않습니다.",
          notExistsTransaction: "트랜잭션이 존재하지 않습니다.",
          withdrawCompleteTitle: "출금 신청 완료",
          withdrawCompleteSubmitButton: "출금 내역 확인하기",
          copyAddressCompleteMessage: "$t(copyMessage)",
          copyWalletNameCompleteMessage: "$t(copyWalletAddressMessage)",
        },
      },
    },
    wallet: {
      recover: {
        guide: {
          title: "$t(setRecoverkitInfo)",
          description1: "지갑을 생성할 때 다운로드받은 복구 키트를 이용하여, ",
          description2: "D 영역에 있는 데이터",
          description3:
            "를 복사해 아래 텍스트 박스에 붙여넣기 하세요.\n\n만약 출력해서 보관하고 있다면, QR 코드 리더기를 활용하여 \nD 영역에 있는 QR코드를 인식하세요.",
          encryptedPassphrasePlaceholder: "D 영역의 Data를 입력하세요.",
          submitButton: "$t(next)",
        },
        index: {
          title: "지갑 비밀번호 복구하기",
          step1Name: "$t(setRecoverkitInfo)",
          step2Name: "$t(setNewPassword)",
          step3Name: "$t(setOTP)",
        },
        otp: {
          submitButton: "$t(authButton)",
          previousButton: "$t(previousStep)",
        },
        passphrase: {
          title: "$t(setNewPassword)",
          description: "새로 사용할 지갑 비밀번호를 입력하세요.",
          passwordPlaceholder: "$t(newPasswordInput)",
          confirmPasswordPlaceholder: "$t(newPasswordConfirmInput)",
        },
      },
      register: {
        index: {
          title: " 마스터 지갑 만들기",
          step1Name: "$t(setName)",
          step2Name: "$t(setWalletPassword)",
          step3Name: "복구 키트 다운로드 받기",
        },
        name: {
          title: "$t(setName)",
          description: "새로 만들 지갑의 이름을 설정하세요.",
          walletName: "$t(walletName)",
          submitButton: "$t(next)",
        },
        passphrase: {
          title: "$t(setWalletPassword)",
          description:
            "새로 만들 마스터 지갑의 비밀번호를 설정하세요.\n지갑 입출금 및 사용자 지갑 생성시 비밀번호가 필요합니다.",
          passwordPlaceholder: "비밀번호 입력",
          confirmPasswordPlaceholder: "비밀번호 재입력",
        },
        recoverkit: {
          title: "복구 키트 다운로드 및 보관",
          description1:
            "분실을 대비해 키 파일이 담긴 복구 키트를 다운로드해주세요.\n복구 키트를 분실하면 ",
          description2: "$t(cannotRecoverWallet)",
          description3: "합니다.",
          pdfDownloadButton: "다운로드 받기",
          pdfDescription1: "인쇄하여 안전한 곳에 보관",
          pdfDescription2:
            "컴퓨터에 보관하면 해킹의 위험이 있으니 반드시 인쇄하여 \n타인의 손이 닿지 않는 안전한 곳에 보관해주세요.",
          pdfDescription3: "인쇄하여 안전한 곳에 보관했습니다.",
          pdfDescription4: "파일을 다운로드 폴더에서 삭제",
          pdfDescription5:
            "보안을 위해 다운로드 폴더에서 복구 키트 파일을 삭제해 주세요.",
          pdfDescription6: "파일을 다운로드 폴더에서 삭제했습니다.",
          previousButton: "$t(previousStep)",
          createWalletButton: "$t(createMasterWallet1)",
          createWalletFailedMessage:
            "지갑 생성에 실패했습니다. 다시 시도해주세요.",
        },
      },
      error: {
        USER_INFO_NOT_FOUND: "유저 정보가 존재하지 않습니다.",
      },
    },
    member: {
      error: {
        duplicatedEmail: {
          description: "는 이미 구성원으로 등록되어 있습니다.",
        },
        alreadyInvitedAccountByDifferentOrganization: {
          description: "는 다른 조직에 속한 계정입니다.",
        },
      },
      emailResend: "초대 메일을 재전송하였습니다.",
      inviteCanceled: "초대를 취소하였습니다.",
    },
  },
  amountStatusBadge: {
    expected: "예상",
    applyEvent: "이벤트",
  },
  dateToolbar: {
    month: "월",
  },
  transferAddressText: {
    toDeposit: "에 입금",
    toWithdraw: "로 출금",
    fromDeposit: "로부터 입금",
  },
  totalCountText: {
    total: "$t(total)",
    count: "$t(count)",
    person: "명",
  },
  withdrawPolicyTable: {
    columns: {
      coinAndToken: "$t(coinToken)",
      walletName: "$t(wallet)",
      oneDayWithdrawAmount: "$t(oneDaysWithdrawalLimit)",
      oneTimeWithdrawAmount: "$t(oneTimeWithdrawalLimit)",
      change: "변경",
    },
    addPolicyButton: "코인/토큰 추가하기",
  },
  userWalletTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    rows: {
      status: {
        creating: "$t(creating)",
        inactive: "$t(creating)",
        failed: "생성 실패",
      },
      addressRefresh: "다시 시도",
    },
    addUserWalletButton: "$t(createUserWallet)",
  },
  transferTable: {
    userWalletMenusName: "해당 지갑으로 이동",
    masterWalletMenusName: "해당 마스터 지갑으로 이동",
    columns: {
      createdAt: "$t(time)",
      sendUser: "$t(wallet)",
      receiveUser: "$t(content)",
      balance: "$t(amount)",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
    onlyMasterWalletName: "마스터 지갑 내역만 보기",
    onlyMasterWalletNameTooltip:
      "사용자 지갑을 제외한 해당 마스터 지갑의 입출금 내역을 확인할 수 있습니다.",
  },
  smartContractTable: {
    columns: {
      createdAt: "$t(time)",
      address: "스마트 컨트랙트 주소",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
  },
  gasFeeUsageTable: {
    columns: {
      createdAt: "$t(time) $t(utc)",
      wallet: "$t(wallet)",
      content: "$t(content)",
      amount: "사용 수수료",
      txHash: "$t(transactionHash)",
      status: "$t(status)",
    },
    exportCSVButton: "CSV로 내보내기",
  },
  depositAddressTable: {
    columns: {
      name: "$t(name)",
      id: "$t(ID)",
      address: "$t(address)",
    },
    addDepositAddressButton: "$t(createDepositAddress)",
  },
  balanceTable: {
    columns: {
      type: "종류",
      name: "$t(name)",
      userWalletSum: "$t(amount)",
      masterWallet: "$t(masterWallet)",
      userWallet: "사용자 지갑 합산",
      sum: "$t(total)",
    },
    myCoinName: "보유한 코인만 보기",
  },
  authorityTable: {
    me: "나",
    columns: {
      name: "$t(name)",
      email: "$t(emailAddress)",
      role: "$t(authority)",
      loginIp: "$t(ipAddress1)",
      loginIpTooltip: "이메일 인증을 거쳐 로그인이 가능한 IP 주소 목록입니다.",
    },
    rows: {
      loginIps: " 외 ",
      loginIpCount: "$t(count)",
    },
    addMemberButton: "팀원 추가하기",
  },
  authorityInfoByRoleTable: {
    columns: {
      role: "항목",
      admin: "관리자 (Admin)",
      spender: "출금 가능 (Spender)",
      viewer: "조회 가능 (Viewer)",
    },
    rows: {
      transfer: "입출금 내역 및 잔고 조회",
      gasFee: "가스비 조회 및 충전",
      deposit: "지갑 입금",
      limitWithdraw: "$t(lessThanWithralLimit)",
      approvalLimitWithdraw: "한도를 초과한 출금 승인/취소",
      limitWithdrawSetting: "지갑 출금 한도 설정",
      createMasterWallet: "$t(createMasterWallet)",
      createDepositAddressAndUserWallet: "입금 주소/사용자 지갑 생성 ",
      smartContractCall: "스마트 컨트랙트 함수 호출 ",
      changePassword: "지갑 비밀번호 변경/복구",
      changeTeamAuth: "팀원 권한 변경",
      apiSecret: "API Secret 재발급",
    },
    footerTitle: " 현재는 API로만 지원되는 기능입니다.",
  },
  allowedIpTable: {
    columns: {
      name: "$t(name)",
      address: "$t(ipAddress1)",
      delete: "$t(delete1)",
    },
    addIpButton: "$t(addIpAddress)",
  },
  allowedAddressTable: {
    columns: {
      name: "$t(name)",
      coinAndToken: "$t(coinToken)",
      address: "$t(address)",
      delete: "$t(delete1)",
    },
    rows: {
      userNameCaption: " 및 하위의 모든 사용자 지갑",
      allCoinAndToken: "$t(allCoinAndToken)",
      userAdddressCaption: " 외 여러 개",
    },
    addAddressButton: "$t(addAddress)",
  },
  balanceReportTable: {
    columns: {
      wallet: "$t(wallet)",
      coinName: "$t(coinName)",
      balance: "$t(balance)",
    },
  },
  walletTab: {
    transferTitle: "입출금 내역",
    balanceTitle: "잔고",
    depositTitle: "$t(deposit)",
    withdrawTitle: "$t(withdrawal)",
    settingTitle: "설정",
    userWalletTitle: "$t(userWallet)",
    depositAddressTitle: "$t(depositAddress)",
    smartcontractTitle: "스마트컨트랙트 호출 내역",
    policyTitle: "$t(approvalPolicy)",
    memberTitle: "$t(member)",
    addressBookTitle: "$t(addressBook.label)",
  },
  gasFeeWalletTab: {
    gasFeeDepositTitle: "$t(deposit)",
    gasFeeUsageTitle: "사용 내역",
  },
  accountTab: {
    accountTitle: "계정 설정",
    languageTitle: "언어",
  },
  inboxTab: {
    all: "전체",
    pending: "대기중",
  },
  custodyRequestWithdrawTab: {
    allTitle: "전체",
    requestTitle: "요청",
    signingTitle: "서명 대기",
    pendingApproveTitle: "승인 대기",
    finalPendingApproveTitle: "최종 승인 대기",
  },
  needGasFeeSnackbar: {
    message1: "가스비 지갑 잔액이 부족합니다.\n잔액이 ",
    message2: " 이상이어야 합니다.",
    submitButton: "가스비 입금하기",
  },
  withdrawCoinSelectbox: {
    groupName: "보유한 코인",
    depositButton: "$t(deposit1)",
    placeholder: "$t(select)",
  },
  walletSelectbox: {
    userWalletAddressName: " 및 하위의 사용자 지갑",
    emptyMenuItemLabel: "다른 지갑이 없습니다.",
    selectedMenuItemLabel: "$t(select)",
  },
  walletAddressSelectbox: {
    userWalletAddressName: "의 사용자 지갑",
    emptyMenuItemLabel: "등록된 주소가 없습니다.",
    selectedMenuItemLabel: "주소를 선택하세요.",
  },
  dateSelectbox: {
    format: "YYYY년 M월",
  },
  coinSelectbox: {
    placeholder: "$t(select)",
  },
  depositProgress: {
    title: "입금 탐색중",
  },
  createWalletLoading: {
    title: "지갑 생성 중",
    ethAndBtcDescription:
      "지갑 생성을 위해 가스비가 사용됩니다.\n생성이 완료될 때까지 약 3분 가량 소요됩니다.",
    klayDescription: "지갑 생성을 위해 가스비가 사용됩니다.",
  },
  userWalletAddressList: {
    emptyListLabel: "사용자 지갑이 없습니다.",
  },
  dashboardLayout: {
    userAccountMenus: {
      account: "계정 정보",
      logout: "로그아웃",
    },
    gasFeeAddressCopyMemssage: "가스비 지갑 주소가 클립보드에 복사되었습니다.",
    depositAddressCopyMemssage: "$t(depositAddressCopyMessage)",
  },
  gasFeesInterval: {
    chargeFeeMessage: "수수료 지갑이 충전되었습니다.",
  },
  checkWalletsInterval: {
    activeMasterWalletMessage: "지갑이 생성되었습니다",
  },
  checkExpiredInterval: {
    otpSubmitName: "$t(authButton)",
    otpCancelButton: "$t(cancel)",
  },
  roleGroupIcon: {
    retrieve: "조회",
    thanLessWithdraw: "$t(lessThanWithralLimit)",
    thanMoreWithdraw: "한도 이상의 출금 승인",
    walletManager: "지갑 관리",
    teamManager: "$t(teamManager)",
  },
  eventStatusIcon: {
    pendingApproval: "승인 대기",
    reject: "거절됨",
    requested: "전송중",
    pending: "채굴 대기",
    mined: "채굴됨",
    confirmed: "완료",
    failed: "전송 실패",
    replaced: "취소됨",
    reverted: "실패",
    internalReverted: "실패",
  },
  setPasswordForm: {
    confirmPasswordError: "$t(verifyNewAndNewConfirmDiff)",
    passwordLabel: "$t(walletPassword)",
    previousButton: "$t(previousStep)",
    nextButton: "$t(next)",
  },
  otpForm: {
    title: "$t(setOTP)",
    description: "Google Authenticator에 보이는 6자리 인증 코드를 입력하세요.",
    otpLabel: "인증 코드",
    otpPlaceholder: "$t(sixDigits)",
  },
  otpGuideForm: {
    title: "OTP 설정하기",
    description1: "최초 로그인시 OTP 설정이 필요합니다. \n",
    description2: "Google Authenticator에서 QR코드를 스캔",
    description3: "해 주세요.",
    androidDownloadButton: "안드로이드폰 다운로드",
    iosDownloadButton: "아이폰 다운로드",
    nextButton: "$t(next)",
    account: "계정",
    key: "키",
    inputGuideDescription:
      "1. [+] 버튼을 클릭 \n2. [설정키 입력] 선택 \n3. 상단의 계정, 키 값 입력",
    qrcode: "QR 코드",
    input: "직접 입력하기",
  },
  depositAddressForm: {
    depositAddress: "$t(depositAddress)",
    depositAddressTooltip: "입금 주소 복사",
    qrCode: "$t(qrCode)",
    depositWarningTitle: "$t(depositWarning)",
    closeButton: "$t(close)",
  },
  withdrawForm: {
    title: "$t(withdraw)",
    toLabel: "$t(receivedAddress)",
    toAddressLabel: "주소 선택",
    toInputLabel: "$t(directInput)",
    toCoinLabel: "$t(sendingCoin)",
    emptyCoinLabel: "보유한 코인이 없습니다.",
    amountPlaceholder: "수량 입력",
    submitButton: "$t(withdraw)",
    btcAmountWarning: "출금 가능 잔액이 부족합니다.",
    ethAndKlayAmountWarning: "잔액이 부족합니다.",
    solanaAmountWarning: "잔액이 부족합니다.",
    eosAmountWarning: "잔액이 부족합니다.",
    minWithdrawableAmount:
      "{{name}}은 {{amount}} {{symbol}} 이상만 출금 가능합니다.",
    balanceTooltip:
      "현재 출금이 가능한 잔액입니다. 결재 진행 중, 처리 대기 또는 처리 중인 수량은 포함하지 않습니다.",
  },
  withdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
  },
  btcWithdrawCalculatorForm: {
    title: "$t(willWithdrawAmount)",
    withdrawAmountLabel: "$t(withdrawAmount)",
    estimatedFeeLabel: "$t(expectedFeeAmount)",
    withdrawTotalAmountLabel: "총 출금 금액",
  },
  gasFeeDepositForm: {
    title: "수수료 입금하기",
    description1: "",
    description2: "을 입금할 수 있습니다.",
    minimalDepositTitle: "$t(minimalExpectedAmount)",
    minimalDepositTooltip: "$t(minimalExpectedAmountMessage)",
    myGasFeeTitle: "$t(myGasFee)",
    gasFeePayment: "$t(gasFeePayment)",
    neededGasFeeWarning: "수수료가 부족합니다.",
    depositAddressTitle: "$t(depositAddress)",
    depositAddress: "$t(address)",
    depositQRCode: "$t(qrCode)",
    depositAddressCopyTooltip: "$t(gasFeeWalletAddressCopy)",
    gasFeeWarningTitle: "$t(depositWarning)",
  },
  withdrawPolicyEmptyStates: {
    title: "출금 한도를 설정하세요",
    description:
      "관리자는 마스터 지갑과 사용자 지갑의\n출금 한도를 설정할 수 있습니다.",
    descriptionForBitcoin: "관리자는 지갑의 출금 한도를 설정할 수 있습니다.",
    submitButton: "코인 추가하기",
  },
  walletEmptyStates: {
    title: "아직 지갑이 없어요",
    descriptionForViewer: "관리자만 마스터 지갑을 만들 수 있습니다.",
    description: "새로운 마스터 지갑을 만들어보세요.",
    submitButton: "마스터 지갑 만들기",
  },
  userWalletsEmptyStates: {
    ethAndKlay: {
      title: "발급된 사용자 지갑이 없어요.",
      description: "새로운 사용자 지갑을 생성해보세요.",
      submitButton: "$t(createUserWallet)",
    },
    btc: {
      title: "발급된 입금 주소가 없어요.",
      description: "새로운 입금 주소를 생성해보세요.",
      submitButton: "$t(createDepositAddress)",
    },
  },
  transferEmptyStates: {
    title: "입출금 내역이 없어요",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  smartContractEmptyStates: {
    title: "스마트 컨트랙트 호출 내역이 없어요",
    description: "SDK 또는 API로 스마트 컨트랙트 함수를 호출해보세요.",
    submitButton: "문서 확인하기",
  },
  searchEmptyStates: {
    title: "검색 결과가 없습니다.",
    description: "다른 조건으로 다시 검색해주세요.",
    submitButton: "초기화",
  },
  requestWithdrawEmptyStates: {
    title: "출금 요청이 없습니다.",
    description: "승인이 필요한 출금이 표시됩니다.",
  },
  registerWalletNeededGasFeeEmptyStates: {
    title: "$t(failedCreateWallet)",
    description:
      "가스비가 부족하여 지갑 생성에 실패했습니다.\n가스비를 충전한 후 다시 시도해주세요.",
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletFailedEmptyStates: {
    title: "$t(failedCreateWallet)",
    description1:
      "지갑 생성이 실패했습니다. 다시 시도하세요.\n문제가 지속될 경우",
    description2: "로 문의해 주세요.",
    submitButton: "다시 시도하기",
  },
  gasFeeUsageEmptyStates: {
    title: "사용 내역이 없어요",
    description: "수수료 지갑에 코인을 입금하세요",
    submitButton: "$t(deposit1)",
  },
  balanceEmptyStates: {
    title: "잔고가 없어요",
    description: "$t(depositCoinAndToken)",
    submitButton: "$t(deposit1)",
  },
  allowedIpListEmptyStates: {
    title: "등록된 IP 주소가 없습니다.",
    description: "API 호출을 허용할 IP 주소를 등록하세요.",
    submitButton: "$t(addIpAddress)",
  },
  allowedIpInactiveListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description: "활성화하면 허용된 IP 주소에서만 API를 호출할 수 있습니다.",
    submitButton: "$t(activate)",
  },
  allowedAddressListEmptyStates: {
    title: "$t(inactiveWhitelist)",
    description: "활성화하면 허용된 주소로만 출금할 수 있습니다.",
    submitButton: "$t(activate)",
  },
  registerAllowedIpEmail: {
    title: "새로운 IP 주소가 화이트리스트에 \n등록되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n새로운 IP 주소가 화이트리스트에 등록되었습니다.\n지금부터 해당 IP 주소에서 API 호출이 가능합니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    registeredIpAddress: "$t(registeredIpAddress)",
    registerAllowedIpWarning: {
      ifNotDirectly: "$t(ifAbnormalAct)",
      removeIpAddress: "대시보드에서 즉시 해당 IP 주소를 삭제하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  registerAllowedAddressEmail: {
    title: "새로운 출금 주소가 화이트리스트에\n등록되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n새로운 출금 주소가 화이트리스트에 등록되었습니다.\n지금부터 해당 주소로 출금이 가능합니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    registerAllowedAddressWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      removeWithdrawAddress: "대시보드에서 즉시 해당 출금 주소를 삭제하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  notAllowedIpCallEmail: {
    title: "허용되지 않은 IP 주소에서 \nAPI 호출이 시도되어 차단하였습니다.",
    description1: "",
    description2: "님, 안녕하세요.\n",
    highlightDescription: "허용되지 않은 IP 주소에서 API 호출이 시도",
    description3:
      "되어 차단하였습니다.\n정상적인 시도라면 대시보드에서 해당 IP 주소를 등록해주세요.",
    registerAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    location: "$t(location)",
    ipAccessTokenWarning: {
      ifNotDirectly: "$t(ifAbnormalAct1)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "IP 주소 화이트리스트 확인하러 가기",
  },
  notAllowedAddressCallEmail: {
    title: "화이트리스팅되지 않은 주소로 \n출금이 시도되어 차단하였습니다.",
    description1: "",
    description2: "님, 안녕하세요.\n",
    highlightDescription: "화이트리스팅되지 않은 주소로 출금이 시도",
    description3:
      "되어 차단하였습니다.\n정상적인 출금 시도라면 대시보드에서 해당 주소를 등록해주세요.",
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    username: "$t(username)",
    withdrawMethod: "$t(withdrawMethod)",
    coinTokenName: "$t(coinTokenName)",
    targetWalletName: "$t(targetWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    withdrawAccessTokenWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      refreshAccessToken: "$t(reissueAccessToken)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "출금 주소 화이트리스트 확인하러 가기",
  },
  limitedRequestWithdrawEmail: {
    title: "한도를 초과한 출금 요청이 발생했습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n한도를 초과한 출금이 발생하였습니다.\n정상적인 출금 요청이라면 대시보드에서 요청을 승인해주세요.",
    withdrawTitle: "$t(withdrawInfo)",
    registerAt: "$t(time)",
    requester: "$t(requester)",
    coinTokenName: "$t(coinTokenName)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    count: "$t(amount)",
    expectedFeeAmount: "$t(expectedFeeAmount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct2)",
      rejectRequestWithdraw: "대시보드에서 즉시 출금 요청을 거절하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "출금 요청 확인하기",
  },
  deleteAllowedIpEmail: {
    title: "IP 주소가 화이트리스트에서 삭제되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\nIP 주소가 화이트리스트에서 삭제되었습니다.\n지금부터 해당 IP 주소에서는 API 호출이 차단됩니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    deletedIpAddress: "삭제한 IP주소",
    confirmButton: "$t(confirmDashbord)",
  },
  deleteAllowedAddressEmail: {
    title: "출금 주소가 화이트리스트에서 삭제되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n출금 주소가 화이트리스트에서 삭제되었습니다.\n지금부터 해당 주소로는 출금하실 수 없습니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    targetWalletName: "$t(targetWallet)",
    addressName: "$t(addressName)",
    coinToken: "$t(coinToken)",
    walletAddress: "$t(address)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOnEmail: {
    title: "IP 주소 화이트리스팅이 활성화되었습니다.",
    description:
      "님, 안녕하세요.\nIP 주소 화이트리스팅이 활성화되었습니다.\n이제 허용된 IP 주소에서만 API 호출이 가능합니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedIpOffEmail: {
    title: "IP 주소 화이트리스팅이 비활성화되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\nIP 주소 화이트리스팅이 비활성화되었습니다.\n이제 모든 IP 주소에서 API 호출이 가능합니다.",
    registerAt: "$t(time)",
    username: "$t(username)",
    allowedIpWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedIp: "대시보드에서 즉시 IP 주소 화이트리스팅을 활성화하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOnEmail: {
    title: "출금 주소 화이트리스팅이 활성화되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n출금 주소 화이트리스팅이 활성화되었습니다.\n이제 화이트리스트에 등록된 주소로만 출금이 가능합니다.",
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    confirmButton: "$t(confirmDashbord)",
  },
  allowedAddressOffEmail: {
    title: "출금 주소 화이트리스팅이 비활성화되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n출금 주소 화이트리스팅이 비활성화되었습니다.\n이제 모든 주소로 출금 가능합니다.",
    registerAt: "$t(time)",
    walletName: "$t(wallet)",
    allowedAddressWarning: {
      ifNotDirectlyActivity: "$t(ifAbnormalAct)",
      allowedAddress:
        "대시보드에서 즉시 해당 지갑의 출금 주소 화이트리스팅을 활성화하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "$t(confirmDashbord)",
  },
  verifyAddressEmail: {
    title: "새로운 IP주소에서 로그인이 시도되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요.\n새로운 IP주소에서 시도된 로그인을 차단하였습니다.\n로그인 차단을 해제하려면 해당 IP주소를 인증해주세요.",
    loginLabel: "$t(loginLabel)",
    loginAt: "$t(time)",
    ipAddress: "$t(ipAddress)",
    browser: "$t(browser)",
    location: "$t(location)",
    loginWarning: {
      ifNotDirectlyLogin: "직접 로그인 하신 것이 아니라면",
      changePassword: "보안을 위해 즉시 계정 비밀번호를 변경하세요.",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    verifyAddressButton: "IP 주소 인증하기",
  },
  custodyRequestWithdrawEmail: {
    title: "커스터디 출금이 요청되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n고객사로부터 커스터디 출금이 요청되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveEmail: {
    title: "커스터디 출금이 승인되었습니다. (1/2)",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 출금이 승인 되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawQRCodeEmail: {
    title: "커스터디 출금 요청 QR 코드가 발급되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 출금 요청 QR 코드가 발급되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    qrCodeName: "QR 코드 생성",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 요청 목록 확인하기",
  },
  custodyRequestWithdrawApproveCompleteEmail: {
    title: "커스터디 출금 승인이 완료 되었습니다.",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 최종 출금 승인이 완료되었습니다.",
    withdrawTitle: "$t(withdrawInfo)",
    approveName: "최종 승인",
    requestWithdrawName: "$t(requestWithdraw)",
    withdrawWalletName: "$t(withdrawWallet)",
    toAddress: "$t(receivedAddress)",
    amount: "$t(amount)",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 출금 내역 확인하기",
  },
  custodyRegisterWalletEmail: {
    title: "커스터디 지갑 생성이 요청되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n고객사로부터 커스터디 지갑 생성이 요청되었습니다.",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveEmail: {
    title: "커스터디 지갑 생성이 승인되었습니다. (2/2)",
    description1: "",
    description2: "님, 안녕하세요. \n커스터디 지갑 생성이 승인되었습니다.",
    approveName: "승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletQRCodeEmail: {
    title: "커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 지갑 생성 요청 QR 코드가 발급되었습니다.",
    qrCodeName: "QR 코드 생성",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 요청 목록 확인하기",
  },
  custodyRegisterWalletApproveCompleteEmail: {
    title: "커스터디 지갑 생성 최종 승인이 완료 되었습니다.",
    description1: "",
    description2:
      "님, 안녕하세요. \n커스터디 지갑 생성 최종 승인이 완료되었습니다.",
    approveName: "최종 승인",
    registerRequestName: "생성 요청",
    registerWalletName: "생성할 지갑",
    requestWithdrawWarning: {
      ifNotDirectlyWithdraw: "$t(ifAbnormalAct)",
      ifHelp: "$t(ifHelp)",
      contact1: "",
      contact2: "$t(contact)",
    },
    confirmButton: "커스터디 지갑 생성 내역 확인하기",
  },
  walletPolicyCard: {
    title: "설정하기",
  },
  allowedAddressActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description:
      "지금부터 등록된 주소로만 출금이 가능합니다.\n출금 제한을 해제하려면 화이트리스팅을 비활성화하세요.",
  },
  allowedAddressActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1: "활성화하면 ",
    description2: "허용된 주소로만 출금",
    description3: "할 수 있습니다.\n정말로 활성화하시겠습니까?",
    closeButton: "$t(close)",
    submitButton: "$t(activate)",
  },
  allowedAddressInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: "지금부터 모든 주소로 출금이 가능합니다.",
  },
  allowedAddressInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: "비활성화하면 앞으로 ",
    description2: "모든 주소로 출금이 가능",
    description3: "합니다.\n정말로 비활성화하시겠습니까?",
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedAddressCompleteDialog: {
    title: "주소 삭제 완료",
    description: "지금부터 해당 주소로 출금이 불가능합니다.",
  },
  deleteAllowedAddressConfirmDialog: {
    title: "주소를 삭제하시겠습니까?",
    description: "을 삭제합니다.",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedAddressCompleteDialog: {
    title: "주소 등록 완료",
    description: "지금부터 해당 주소로 출금이 가능합니다.",
  },
  registerAllowedAddressDialog: {
    title: "$t(addAddress)",
    directInputLabel: "$t(directInput)",
    selectedWalletLabel: "지갑 선택",
    nameLabel: "$t(name)",
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinLabel: "$t(emptyCoin)",
    addressLabel: "$t(address)",
    walletLabel: "$t(wallet)",
    closeButton: "$t(close)",
    addButton: "$t(addButton)",
  },
  allowedIpActiveCompleteDialog: {
    title: "$t(completeActiveWhitelist)",
    description: "$t(availableCurrentRegisteredIp)",
  },
  allowedIpInactiveCompleteDialog: {
    title: "$t(completeInactiveWhitelist)",
    description: "지금부터 모든 IP 주소에서 API 호출이 가능합니다.",
  },
  allowedIpInactiveConfirmDialog: {
    title: "$t(isInactiveWhitelisting)",
    description1: "비활성화하면 ",
    description2: "모든 IP 주소에서 API 호출",
    description3: "을 할 수 있습니다.\n정말로 비활성화하시겠습니까?",
    closeButton: "$t(close)",
    submitButton: "$t(inactive)",
  },
  deleteAllowedIpCompleteDialog: {
    title: "IP 주소 삭제 완료",
    description: "지금부터 해당 IP 주소에서는 API 호출이 차단됩니다.",
  },
  deleteAllowedIpConfirmDialog: {
    title: "IP 주소를 삭제하시겠습니까?",
    description: "을 삭제합니다.",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  registerAllowedIpCompleteDialog: {
    title: "IP 주소 등록 완료",
    description: "지금부터 해당 IP 주소에서 API 호출이 가능합니다.",
  },
  registerAllowedIpDialog: {
    title: "$t(addIpAddress)",
    description: "API 호출을 허용할 IP 주소를 등록할 수 있습니다.",
    nameLabel: "$t(name)",
    ipAddressLabel: "$t(ipAddress1)",
    ipAddressPlaceholder: "IPv4 주소 (0.0.0.0~255.255.255.255)",
    closeButton: "$t(close)",
    submitButton: "등록하기",
  },
  registerAndAllowedIpActiveCompleteDialog: {
    title: "화이트리스팅 활성화 및 주소 등록 완료",
    description: "$t(availableCurrentRegisteredIp)",
  },
  registerAndAllowedIpActiveConfirmDialog: {
    title: "$t(isActiveWhitelisting)",
    description1:
      "활성화하면 등록되지 않은 IP 주소에서 API 호출이 차단됩니다.\n이미 지갑과 연동된 응용 프로그램이 API를 호출하고 있는 경우,\nIP 주소 등록 후 활성화를 해주세요. 그렇지 않으면 ",
    description2: "응용 프로그램의 동\n",
    description3: "작이 멈출 수",
    description4: "있습니다.\n          \n활성화하시겠습니까?",
    closeButton: "$t(close)",
    submit1Button: "IP 주소 등록 후 활성화",
    submit2Button: "$t(activate)",
  },
  createAccessTokenCompleteDialog: {
    title: "Access Token 생성 완료",
    description: "새로운 Access Token이 생성되었습니다.",
    submitButton: "$t(confirm)",
  },
  createAccessTokenDialog: {
    title: "Access Token 생성하기",
    description: "새롭게 생성할 Access Token의 유효 기간을 설정하세요.",
    expiresInLabel: "유효 기간 (초)",
  },
  createApiSecretCompleteDialog: {
    title: "API Secret 재발급 완료",
    description: "새로운 API Secret이 생성되었습니다.",
    submitButton: "$t(confirm)",
  },
  refreshAccessTokenDialog: {
    title: "Access Token 재발급하기",
    description:
      "기존에 생성하였던 Access Token은 더이상 사용할 수 없습니다.\n재발급하시겠습니까?",
    submitButton: "$t(reissue)",
  },
  refreshApiSecretDialog: {
    title: "API Secret 재발급하기",
    description:
      "기존에 생성하였던 API Secret은 더이상 사용할 수 없습니다.\n재발급하시겠습니까?",
    submitButton: "$t(reissue)",
  },
  authorityInfoByRoleDialog: {
    title: "권한 안내",
    description: "역할별로 사용 가능한 권한은 다음과 같습니다.",
    submitButton: "$t(confirm)",
  },
  authorityIpAddressesDialog: {
    ipAddressLabel: "$t(ipAddress1)",
    createdAtLabel: "등록 시간 $t(utc)",
    submitButton: "$t(confirm)",
  },
  createAuthorityDialog: {
    title: "팀원을 추가하고 싶으신가요?",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  deleteAuthorityCompleteDialog: {
    title: "권한 삭제 완료",
    description: "성공적으로 권한이 삭제되었습니다.",
    submitButton: "$t(confirm)",
  },
  deleteAuthorityDialog: {
    title: "권한 삭제하기",
    description: "의 권한을 삭제합니다.",
    closeButton: "$t(close)",
    submitButton: "$t(delete)",
  },
  updateAuthorityCompleteDialog: {
    title: "권한 변경 완료",
    description: "성공적으로 권한이 변경 완료되었습니다.",
    submitButton: "$t(confirm)",
  },
  updateAuthorityDialog: {
    title: "권한 변경하기",
    description1: "",
    description2: "의 권한을\n다음과 같이 변경합니다.",
    authorityLabel: "$t(authority)",
    closeButton: "$t(close)",
    submitButton: "$t(change)",
  },
  registerDepositAddressCompleteDialog: {
    title: "입금 주소 생성 완료",
    description: " 가 생성되었습니다.",
  },
  registerDepositAddressDialog: {
    title: "새로운 입금 주소 만들기",
    description1: "",
    description2: " 지갑의 입금 주소를 생성합니다.",
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  exportGasFeeTransferDialog: {
    title: "수수료 지갑 사용 내역 내보내기",
    description: "월별 내역을 CSV 파일로 다운로드할 수 있습니다.",
    warningAlert1: "전송중 혹은 채굴 대기 상태일 경우 ",
    warningAlert2: "미확정된 수량으로 표기",
    warningAlert3: "됩니다.",
    closeButton: "$t(close)",
    submitButton: "CSV 다운로드",
  },
  smartContractDetailDialog: {
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    title: "스마트컨트랙트 호출 상세 내역",
    createdAtLabel: "$t(time)",
    smartContractAddressLabel: "스마트컨트랙트 주소",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  smartContractTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
    warningAlert2:
      "동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지\n트랜잭션은 취소됩니다.",
    warningAlert3: "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
    closeButton: "재전송 취소",
    submitButton: "재전송하기",
  },
  transferRetryTransactionConfirmDialog: {
    title: "$t(retryRealGasFeeMessage)",
    description: "$t(newTransactionMessage)",
    currentGasPriceLabel: "$t(realGasFee)",
    currentGasPriceTooltipMessage: "$t(realNetworkBasedGasFee)",
    warningTitle: "$t(doNotBeSurprised)",
    warningAlert1:
      "가스비가 더 낮게 설정된 기존 트랜잭션이 먼저 채굴될 수도 있습니다.",
    warningAlert2:
      "동일한 트랜잭션이 여러 개 발생되지만, 그 중에 하나가 채굴되면 나머지\n트랜잭션은 취소됩니다.",
    warningAlert3: "거래가 중복으로 확정되는 일은 절대 발생하지 않습니다.",
    closeButton: "재전송 취소",
    submitButton: "재전송하기",
  },
  transferSearchOptionDialog: {
    btcName: {
      description:
        "입금된 지갑, 입금 주소의 이름, 입금된 주소\n혹은 출금한 주소로 검색할 수 있습니다.",
    },
    btcTransactionHash: {
      description: "트랜잭션 해시로 검색할 수 있습니다.",
    },
    ethKlayName: {
      description: "입금을 보낸 주소 또는 출금한 주소로 검색할 수 있습니다.",
    },
    ethKlayTransactionHash: {
      description: "트랜잭션 해시로 검색할 수 있습니다.",
    },
    solanaName: {
      description: "입금을 보낸 주소 또는 출금한 주소로 검색할 수 있습니다.",
    },
    solanaTransactionHash: {
      description: "트랜잭션 해시로 검색할 수 있습니다.",
    },
    eosName: {
      description: "입금을 보낸 주소 또는 출금한 주소로 검색할 수 있습니다.",
    },
    eosTransactionHash: {
      description: "트랜잭션 해시로 검색할 수 있습니다.",
    },
    title: "검색 옵션",
    description:
      "다음과 같은 검색 옵션을 사용할 수 있습니다.\n현재 부분 검색은 지원하지 않습니다.",
    contentLabel: "$t(content)",
    transactionHashLabel: "$t(transactionHash)",
    submitButton: "$t(confirm)",
  },
  transferStatusDialog: {
    REQUEST_PENDING: {
      description: "책임자 혹은 중간 책임자의 결재를 기다리는 상태",
    },
    REQUEST_REJECT: {
      description: "책임자 혹은 중간 책임자가 결재를 거절함",
    },
    PROCEEDING_PENDING: {
      description: "코다가 해당 내역을 처리 해주기를 기다리는 상태",
    },
    PROCEEDING_REJECT: {
      description: "코다에서 해당 내역의 처리를 거절함",
    },
    PROCEEDING_CANCEL: {
      description: "결재자가 해당 내역을 취소함",
    },
    PROCEEDING: {
      description: "코다에서 처리가 진행되고 있는 상태",
    },
    TRANSFER: {
      description: "트랜잭션이 발생되어 전송되고 있는 상태",
    },
    TRANSFER_COMPLETE: {
      description: "트랜잭션을 전송 완료함",
    },
    TRANSFER_FAILED: {
      description: "트랜잭션을 전송 실패함",
    },
    DEPOSIT: {
      description: "입금 트랜잭션이 확인되어 확정을 기다리고 있는 상태",
    },
    DEPOSIT_COMPLETE: {
      description: "입금 트랜잭션이 확정됨",
    },
    submitButton: "$t(confirm)",
  },
  transferWithdrawDetailDialog: {
    titleByTransferType: {
      DEPOSIT: {
        title: "입금 상세 내역",
      },
      WITHDRAWAL: {
        title: "출금 상세 내역",
      },
    },
    retryGasPriceDescription: "$t(retryGasFeeMessage)",
    retryGasPriceButton: "$t(retryRealGasFee)",
    gasPriceLabel: "$t(gasPrice)",
    createdAtLabel: "$t(time)",
    toAddressLabel: "보낸 주소",
    fromAddressLabel: "$t(receivedAddress)",
    amountLabel: "$t(amount)",
    statusLabel: "$t(status)",
    gasFeeLabel: "$t(fee)",
    transactionHashLabel: "$t(transactionHash)",
    closeButton: "$t(close)",
  },
  registerUserWalletCompleteDialog: {
    title: "사용자 지갑 생성 요청 완료",
    description: " 가 생성되었습니다.",
  },
  registerUserWalletDialog: {
    title: "새로운 사용자 지갑 만들기",
    description1: "",
    description2: " 마스터 지갑 하위에 새로운 사용자 지갑을 생성합니다.",
    nameLabel: "$t(name)",
    closeButton: "$t(close)",
    submitButton: "$t(create)",
  },
  withdrawCompleteDialog: {
    description1: "",
    description2:
      "의 출금 신청이 완료 되었습니다.\n트랜잭션이 확정되려면 일정 시간이 소요될 수 있습니다.",
    closeButton: "$t(close)",
  },
  withdrawOTPDialog: {
    title: "다음과 같이 출금합니다.",
    description1: "마지막으로 ",
    description2: "주소와 보낼 코인을 한번 더 확인",
    description3:
      "하신 후,\nGoogle Authenticator에 보이는 6자리 인증 코드를 입력하세요.",
    fromAddressLabel: "$t(receivedAddress)",
    toCoinLabel: "$t(sendingCoin)",
    otpCodeLabel: "OTP 인증 코드",
    otpCodePlaceholder: "$t(sixDigits)",
    closeButton: "$t(close)",
    submitButton: "$t(withdraw)",
  },
  withdrawPassphraseDialog: {
    normal: {
      title: "지갑 비밀번호 입력하기",
      description: "의 비밀번호를 입력하세요.",
    },
    withdraw: {
      title: "출금 인증하기",
      description1: "출금 인증을 위해 ",
      description2: "",
      description3: "의 비밀번호를 입력하세요.",
    },
    passwordLabel: " 비밀번호",
    closeButton: "$t(close)",
    submitButton: "$t(authButton)",
  },
  withdrawRejectCompleteDialog: {
    title: "출금이 거절되었습니다.",
    description: "요청된 출금이 취소 처리되었습니다.",
    submitButton: "$t(confirm)",
  },
  withdrawRejectDialog: {
    title: "출금을 거절하시겠습니까?",
    description: "요청된 출금이 취소 처리됩니다.",
    cancelButton: "$t(cancel)",
    submitButton: "출금 거절",
  },
  withdrawPolicyCompleteDialog: {
    title: "출금 한도 설정 완료",
    description: "의 출금 한도가 설정되었습니다.",
    submitButton: "$t(confirm)",
  },
  withdrawPolicyInfoDialog: {
    masterWalletPolicy: {
      infoTitle: "$t(masterWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    userWalletPolicy: {
      infoTitle: "$t(userWallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3: "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
      infoList4: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
    },
    bitCoin: {
      infoTitle: "$t(wallet)",
      infoList1: "$t(warningWIthdrawalLimit2)",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    title: "$t(withdrawalLimit)",
    walletType: {
      masterWallet: "$t(masterWallet)",
      userWallet: "$t(userWallet)",
    },
    submitButton: "$t(confirm)",
  },
  withdrawPolicySettingDialog: {
    update: {
      title: " 출금 한도 변경",
      description1: "의 ",
      description2: " 출금 한도를 변경합니다.",
      submitButton: "$t(change)",
    },
    create: {
      title: " 출금 한도 설정",
      description1: "의 ",
      description2: " 출금 한도를 설정합니다.",
      submitButton: "$t(next)",
    },
    oneDayAmountLabel: "$t(oneDaysWithdrawalLimit)",
    oneTimeAmountLabel: "$t(oneTimeWithdrawalLimit)",
    userWallet: {
      infoList1:
        "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
      infoList2: "$t(warningWithdrawalLimit1)",
      infoList3: "마스터 지갑 하위에 있는 사용자 지갑에 일괄 적용됩니다.",
      infoList4: "개별 사용자 지갑에 해당 한도가 각각 적용됩니다.",
    },
    masterWallet: {
      infoList1:
        "'출금 가능' 권한의 팀원은 출금 한도 이내에서만 출금 가능합니다.",
      infoList2: "$t(warningWithdrawalLimit1)",
    },
    closeButton: "$t(close)",
  },
  accessTokenKeepDialog: {
    title: " 후에 로그아웃됩니다.",
    description:
      "접속한 지 30분이 지나 보안을 위해 자동으로 로그아웃됩니다.\n로그인 상태를 유지하려면 OTP 인증을 진행해 주세요.",
    logoutButton: "로그아웃",
    submitButton: "로그인 유지",
  },
  changeUserNameCompleteDialog: {
    title: "사용자 이름 변경 완료",
    description: "새로운 사용자 이름이 설정 되었습니다.",
    submitButton: "$t(confirm)",
  },
  changeUserNameAndLanguageCompleteDialog: {
    title: "사용자 이름 및 언어 변경 완료",
    description: "새로운 사용자 이름 및 언어가 설정 되었습니다.",
    submitButton: "$t(confirm)",
  },
  changeUserPasswordCompleteDialog: {
    title: "계정 비밀번호 변경 완료",
    description: "새로운 계정 비밀번호가 설정 되었습니다.",
    submitButton: "$t(confirm)",
  },
  findOTPDialog: {
    title: "$t(forgottenOTP)",
    description: "$t(contactEmail)",
    submitButton: "$t(confirm)",
  },
  firstDepositConfirmDialog: {
    title: "혹시 가스비를 입금하려 하시나요?",
    description1:
      "모든 지갑은 가스비 지갑에 예치된 가스비를 이용합니다.\n가스비는 ",
    description2: "가스비 지갑에 입금",
    description3: "하셔야 합니다.",
    closeButton: "$t(dontShowAgain)",
    gasFeeButton: "가스비 지갑으로 이동",
    submitButton: "확인했습니다",
  },
  gasFeeDepositDialog: {
    common: {
      title: "수수료를 충전해 주세요.",
      description:
        "지갑을 생성하려면 수수료를 최소 예치 금액 이상 충전해야 합니다.",
      statusDone: "충전 완료",
    },
    makeWallet: {
      closeButton: "$t(close)",
      submitButton: "$t(next)",
    },
    deposit: {
      title: "수수료 충전하기",
      description: "을\n입금할 수 있습니다.",
      statusDone: "입금 되었습니다",
      closeButton: "$t(close)",
    },
    retryWallet: {
      closeButton: "$t(close)",
      submitButton: "지갑 생성 재시도하기",
    },
    retryGasFeeWallet: {
      closeButton: "취소하기",
      submitButton: "$t(createMasterWallet1)",
    },
    minimalDeposit: "$t(minimalExpectedAmount)",
    minimalDepositTooltipMessage: "$t(minimalExpectedAmountMessage)",
    myGasFeeLabel: "$t(myGasFee)",
    myGasFeeWarningList: "최소 예치 금액 이상 입금해 주세요.",
    depositAddressLabel: "$t(depositAddress)",
    depositType: {
      address: "$t(address)",
      qrCode: "$t(qrCode)",
    },
    gasFeeAddressCopy: "$t(gasFeeWalletAddressCopy)",
    depositCaption: "마스터 지갑 주소와는 다른 별도의 지갑 주소입니다.",
    depositWarning: {
      warningTitle: "$t(depositWarning)",
    },
  },
  registerGasFeeWalletFailedDialog: {
    title: "지갑 생성 실패",
    description:
      "가스비가 부족해 지갑 생성에 실패했습니다.\n가스비를 충전해 주세요.",
    closeButton: "지갑 생성 취소하기",
    submitButton: "$t(chargeGasFee)",
  },
  registerWalletBlockchainDialog: {
    title: "새로운 지갑 만들기",
    platformLabel: "메인넷",
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  registerWalletCoinDialog: {
    title: "코인/토큰 선택",
    description: "출금 한도를 적용할 코인을 선택하세요.",
    coinAndTokenLabel: "$t(coinToken)",
    emptyCoinAndTokenLabel: "$t(emptyCoin)",
    closeButton: "$t(close)",
    submitButton: "$t(next)",
  },
  registerWallet: {
    title: "지갑 만들기",
    steps: {
      step1: "이름 설정하기",
      step2: "지갑 주소 설정하기",
      step3: "계정 비밀번호 입력",
      step4: "$t(walletCreation)",
    },
    setWalletNameForm: {
      title: "이름 설정하기",
      description: "새로 만들 지갑의 이름을 설정하세요.",
    },
    setWalletAddressForm: {
      title: "지갑 주소 설정하기",
      description:
        "사용할 지갑 주소를 설정하세요. 지갑 주소는 알파벳 소문자 a부터 z와 \n숫자 1에서 5까지 사용할 수 있으며, 총 12자리여야 합니다.",
      randomInput: "랜덤 설정",
      manualInput: "직접 입력",
    },
    setAccountPasswordForm: {
      title: "계정 비밀번호 입력하기",
      description: "본인 인증을 위해 계정 비밀번호를 입력해주세요.",
      label: "계정 비밀번호",
    },
    requestRegisterWalletOTPForm: {
      title: "지갑을 생성합니다.",
      wallet: "생성할 지갑",
    },
    error: {
      DUPLICATED_WALLET_NAME:
        "동일한 이름의 {walletName} 지갑이 있습니다. 다른 이름을 입력하세요.",
      WALLET_NOT_FOUND: "지갑 정보가 존재하지 않습니다.",
      DUPLICATED_WALLET_ADDRESS: "이미 사용중인 지갑 주소입니다.",
      ADDRESS_MAX_LENGTH: "일반 지갑 주소는 12자리여야 합니다.",
    },
  },
  settingCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  templateCompleteDialog: {
    submitButton: "$t(confirm)",
  },
  userWalletPickerDialog: {
    closeButton: "$t(close)",
    submitButton: "선택하기",
  },
  walletSafePrintDialog: {
    title: "인쇄물을 안전하게 보관하셨나요?",
    description1: "복구 키트를 분실하면 ",
    description2: "$t(cannotRecoverWallet)",
    description3: "하며,\n헤네시스에서는 분실에 대한 책임을 지지 않습니다.",
    submitButton: "네, 확인했습니다.",
  },
  materialDrawer: {
    requestWithdrawName: "$t(requestWithdraw)",
    apiSyncName: "$t(apiSync)",
    teamName: "$t(teamManager)",
    walletName: "$t(wallet)",
    emptyWalletName: "지갑이 없습니다.",
    gasFeeName: "수수료 지갑",
    gasFeePaymentName: "$t(gasFeePayment)",
    balanceReport: "전체 잔고",
    inboxRequests: "내가 받은 결재",
    outboxRequests: "내가 올린 결재",
    proceedingRequests: "전체 처리 내역",
    organization: "조직",
    coins: "코인 목록",
  },
  requestWithdrawItem: {
    requestNameCaption1: "",
    requestNameCaption2: "의 요청",
    approveComplete: "승인됨",
    approveButton: "승인",
    rejectComplete: "거절됨",
    rejectButton: "거절",
  },
  languageSelectbox: {
    ko: "한국어",
    en: "English",
  },
  myMenu: {
    authority: "권한 설정",
    account: "계정 설정",
    language: "언어 변경",
    logout: "로그아웃",
  },
  requestWithdrawList: {
    column: {
      requesterAndDate: "$t(requester) / $t(time) $t(utc)",
      from: "$t(wallet)",
      to: "$t(receivedAddress)",
      amount: "$t(amount)",
      status: "$t(status)",
    },
  },
  createRequestOrganizationCompleteDialog: {
    title: "등록 요청 완료",
    description: "성공적으로 등록 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
  updateRequestOrganizationCompleteDialog: {
    title: "수정 요청 완료",
    description: "성공적으로 수정 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
  deleteRequestOrganizationCompleteDialog: {
    title: "삭제 요청 완료",
    description: "성공적으로 삭제 요청 되었습니다.",
    submitButton: "$t(confirm)",
  },
  updateAuthorityOrganizationMemberCompleteDialog: {
    title: "관리 권한 변경 완료",
    description1: "",
    description2: "의 관리 권한을 변경하였습니다.",
  },
  updateOrganizationMemberDialog: {
    title: "구성원 권한 변경하기",
    description: "권한을 다음과 같이 변경합니다.",
    authority: "관리 권한",
  },
  authorityTab: {
    title: "관리 권한",
    organizationSuperAdmin: {
      description1: "조직 구성원에게 관리자 역할을 부여할 수 있습니다.",
      description2: "지갑 관리자와 구성원 관리자가 가진 모든 권한을 갖습니다.",
    },
    organizationWalletsAdmin: {
      description1: "지갑을 생성할 수 있습니다.",
      description2: "모든 지갑에서 책임자 역할을 갖습니다.",
    },
    organizationUsersAdmin: {
      description1: "조직에 구성원을 추가하고 삭제할 수 있습니다.",
      description2: "조직 구성원에게 관리자 역할은 부여할 수 없습니다.",
    },
  },
  walletRoleTab: {
    title: "지갑별 역할",
    changeRole: "역할 변경하기",
    empty: "소속된 지갑이 없습니다.",
    walletOwner: {
      description: "지갑 내 결재를 담당합니다.",
    },
    walletManager: {
      description: "지갑 내 중간 결재를 담당합니다.",
    },
    walletMember: {
      description: "출금 및 주소록 등록/삭제 후 결재를 요청할 수 있습니다.",
    },
    walletViewer: {
      description: "지갑을 조회할 수 있습니다.",
    },
  },
  loginIpsTab: {
    title: "로그인 IP 주소",
    createdAt: "$t(registeredAt)",
    ipAddress: "IP 주소",
    empty: {
      title: "등록된 IP 주소가 없습니다.",
      description: "인증이 완료된 로그인 IP 주소가 표시됩니다.",
    },
  },
  updateWalletMemberRoleDialog: {
    title: "역할 변경하기",
    description1: "의 ",
    description2: " 지갑 내 역할을 다음과 같이 수정합니다.",
    walletOwner: {
      description:
        "지갑 내 최종 결재를 담당합니다. \n지갑 구성원 및 결재선을 관리할 수 있습니다.",
    },
  },
  deleteOrganizationMemberCompleteDialog: {
    title: "삭제 완료",
    description: "을 삭제했습니다.",
  },
  deleteOrganizationMemberDialog: {
    title: "구성원 삭제하기",
    description1: "다음과 같은 구성원을 ",
    description2: "에서 삭제합니다.",
    caution1:
      "계정을 삭제할 시 모든 계정 정보가 삭제되며, 추후 동일한 이메일 주소로 계정을 추가하더라도 데이터 복구가 불가능합니다.",
    caution2:
      "단, 해당 계정이 이미 요청한 결재 또는 승인/거절한 결재는 삭제되지 않습니다.",
  },
  organizationMemberTable: {
    resendInvitation: "초대 재전송",
    cancelInvitation: "초대 취소하기",
    inviteMember: "구성원 초대하기",
  },
  accountSignupCompleteDialog: {
    title: "가입 완료",
    description: "가입이 완료되었습니다. 로그인해주세요.",
  },
  registerAccountForm: {
    signup: "가입하기",
    passwordPlaceholder: "8글자 이상 입력",
    confirmPasswordPlaceholder: "비밀번호 재입력",
  },
  resetAccountPasswordForm: {
    title: "새로운 비밀번호를 설정하세요.",
    description:
      "<span>영문 대문자 또는 소문자, 숫자 한 개 이상, 특수문자를 포함한 8자 이상</span>의 새로운 비밀번호를 설정해주세요.",
    passwordPlaceholder: "새로운 비밀번호",
    confirmPasswordPlaceholder: "비밀번호 재입력",
    submit: "설정하기",
    complete: {
      title: "새로운 비밀번호가 설정되었습니다.",
      description: "새로운 비밀번호로 다시 로그인 하세요.",
    },
  },
  cancelRequestAddressBookDialog: {
    description: "취소할 주소록 정보를 다시 한번 확인해주세요.",
    create: "주소록 등록 요청을 취소합니다.",
    delete: "주소록 삭제 요청을 취소합니다.",
  },
  cancelRequestCompleteDialog: {
    title: "요청 취소 완료",
    description: "요청이 취소 되었습니다.",
  },
  rejectRequestAddressBookDialog: {
    create: "주소록 등록을 반려합니다.",
    delete: "주소록 삭제를 반려합니다.",
    description: "반려할 주소록 정보를 확인하세요.",
  },
  requestAddressBookDialog: {
    create: {
      title: "주소록 등록을 결재합니다.",
      description: "등록할 주소록 정보를 다시 한번 확인해주세요.",
    },
    delete: {
      title: "주소록 삭제를 결재합니다.",
      description: "삭제할 주소록 정보를 다시 한번 확인해주세요.",
    },
  },
  cancelRequestWithdrawDialog: {
    title: "출금 요청을 취소합니다.",
    description: "취소할 출금 정보를 다시 한번 확인해주세요.",
  },
  rejectRequestWithdrawDialog: {
    title: "출금을 반려합니다.",
    description: "반려할 출금 정보를 확인하세요.",
  },
  requestWithdrawDialog: {
    title: "출금을 결재합니다.",
    description: "출금 정보를 다시 한번 확인해주세요.",
  },
  clientRequestApprovalPolicy: {
    walletOwner: "책임자",
    walletManager: "중간 책임자 이상",
    walletManagerAndOwnerSequentialApproval: "중간 책임자 및 책임자",
    required: "결재 필요",
  },
  inboxTable: {
    empty: {
      title: "받은 요청이 없습니다.",
      description: "실무자 또는 중간책임자의 요청을 확인할 수 있습니다.",
      checkRole: "구성원별 역할 확인",
    },
  },
  outboxTable: {
    empty: {
      title: "보낸 요청이 없습니다.",
      description: "책임자 또는 중간책임자에게 승인 요청을 보낼 수 있습니다.",
      checkRole: "구성원별 역할 확인",
    },
  },
  requestTable: {
    check: "요청 확인",
    detail: "자세히 보기",
    content: "내용",
    createdAt: "요청",
  },
  setAccountNewPasswordForm: {
    description:
      "마지막으로 <span>비밀번호를 변경하신지 {{passphraseLastModifiedDayBefore}}일</span>이 지났습니다. <br/>코다에서는 회원님의 자산을 안전하게 보호하기 위해 {{passphraseExpiredPeriodDays}}일동안 비밀번호를 변경하지 않은 경우 비밀번호 변경을 안내하고 있습니다. <br/><br/><span>영문 대문자 또는 소문자, 숫자 한 개 이상, 특수문자를 포함한 8자 이상</span>의 새로운 비밀번호를 설정해주세요.",
    complete: {
      description: "계정에 새로운 비밀번호가 적용됩니다.",
    },
  },
  passwordExpiredDialog: {
    title: "비밀번호를 변경해 주세요.",
    changeLater: "다음에 변경하기",
  },
  noticeDialog: {
    title: "가상자산 입출금시 유의사항",
  },
  alreadyLoginDirectLogoutDialog: {
    title: "로그아웃 되었습니다.",
    description: "다른 브라우저에서 로그인해 로그아웃 되었습니다.",
  },
  firstOrganizationMemberDialog: {
    title: "환영합니다.",
    description:
      "코다 커스터디 대시보드에 오신 것을 환영합니다. \n구성원을 초대해 조직의 지갑을 관리해보세요.",
    invite: "구성원 초대하기",
  },
  registerCoinCompleteDialog: {
    title: "코인 등록 완료",
    description:
      " 등록이 완료되었습니다. <br/>이제 <br/>{{wallet}}에서 사용 가능합니다.",
  },
  registerCoinOTPDialog: {
    title: "다음과 같은 코인을 등록합니다.",
    description:
      "등록할 코인 정보를 한번 더 확인하신 후 \nGoogle Authenticator에 보이는 6자리 인증 코드를 입력하세요.",
  },
  registerCoinDialog: {
    title: "코인 등록하기",
    description: "사용할 코인을 등록하세요.",
    coinsToRegister: "등록할 코인",
    empty: "등록할 수 있는 코인이 없습니다.",
    emptyAlert1: "목록에 없는 코인을 사용하고 싶으실 경우",
    emptyAlert2: " 으로 문의 부탁드립니다.",
  },
  deleteCoinOTPDialog: {
    title: "코인 삭제하기",
    description: "다음과 같은 코인을 삭제합니다.",
  },
  deleteCoinCompleteDialog: {
    title: "코인 삭제 완료",
    description: "이 삭제되었습니다.",
  },
  registerWalletEmptyStates: {
    title: "처리 대기중입니다.",
    description1: "안내된 시간 내에 처리가 완료되지 않을 시 \n고객센터 (",
    description2: ")로 문의주세요.",
    checkDetail: "상세 내역 확인하기",
    alert1: "지갑 생성까지 <span>1 영업일이 소요</span>됩니다.",
    alert2: "지갑 생성 및 출금 요청은 <span>하루에 1건씩 처리</span>됩니다.",
    alert3:
      "만일 처리 대기 중인 출금 요청이 있다면, 새로운 지갑 생성 요청은 지갑 생성 완료 후 1영업일 뒤에 처리됩니다.",
  },
  requestProceedingEmptyStates: {
    title: "처리 내역이 없습니다.",
    description: "결재가 완료된 출금 혹은 지갑 생성 내역이 표시됩니다.",
  },
  withdrawSalesAlert: {
    alert1: "출금 완료까지 <span>1 영업일이 소요</span>됩니다.",
    alert2: "지갑 생성 및 출금 요청은 <span>하루에 1건씩 처리</span>됩니다.",
    alert3:
      "만일 처리 대기 중인 출금 요청이 있다면, 새로운 출금 요청은 지갑 생성 완료 후 1영업일 뒤에 처리됩니다.",
  },
  registerAddressBookDialog: {
    title: "주소록 추가하기",
    previous: "뒤로",
    label: {
      name: "지갑 주소명",
    },
    placeholder: {
      address: "등록할 지갑 주소를 입력하세요.",
      memo: "등록할 MEMO를 입력하세요. (Optional)",
      name: "주소록의 이름을 설정하세요.",
    },
    receiverForm: {
      title: "수취인 정보 입력하기",
      koreanLastName: "한글 성",
      koreanFirstName: "한글 이름",
      englishLastName: "영문 성",
      englishFirstName: "영문 이름",
      walletType: {
        label: "지갑 유형",
        personal: "본인 확인 지갑",
        vasp: "거래소 (혹은 타 가상자산사업자)",
        info: "[본인 확인 지갑]은 “본인 소유 지갑 확약서”에 기재된 주소 혹은 출금 사유를 입증할 수 있는 증빙 자료를 제출한 경우에 가능합니다.",
      },
      vasp: "가상자산사업자",
      receiverType: {
        label: "수취인 유형",
        natural: "개인",
        legal: "법인",
      },
      corporationName: {
        label: "법인명",
        placeholder: "법인명을 입력하세요.",
      },
      representativeName: "법인 대표자 성명",
      receiverName: "수취인 성명",
      receiverAddress: {
        label: "수취인 주소",
        placeholder:
          "수취인 주소를 입력하세요. (ex. 서울시 강남구 테헤란로 27길 8)",
      },
      receiverPhoneNumber: {
        label: "수취인 연락처",
        placeholder: "수취인 연락처를 입력하세요. (ex. 010-1234-5678)",
      },
    },
  },
  walletMemberTable: {
    columns: {
      role: "역할",
    },
    empty: {
      title: "등록된 구성원이 없어요.",
      description: "구성원을 추가해주세요.",
    },
    addMember: "구성원 추가하기",
  },
  cancelRegisterWalletCompleteDialog: {
    title: "지갑 생성 취소 완료",
    description: "지갑 생성이 취소 되었습니다.",
  },
  cancelRegisterWalletDialog: {
    title: "지갑 생성을 취소합니다.",
    description: "취소할 지갑 생성 정보를 다시 한번 확인해주세요.",
    registerWalletName: "생성할 지갑",
  },
  requestRegisterWalletCompleteDialog: {
    title: "지갑 생성 요청 완료",
    description:
      "지갑 생성이 처리 요청되었습니다. <br/>지갑 생성까지 <span>1영업일이 소요</span>됩니다.",
  },
  updateWalletNameCompleteDialog: {
    title: "지갑 이름 변경 완료",
    description: "<span>{{name}}</span>으로 변경되었습니다.",
  },
  updateWalletNameDialog: {
    title: "지갑 이름 변경",
    description: "변경할 지갑 이름을 입력하세요.",
    placeholder: "새로운 지갑 이름 입력",
  },
  requestWithdrawCompleteDialog: {
    title1: "출금 결재 요청 완료",
    title2: "출금 처리 요청 완료",
    description1:
      "<span>{{coin}}</span> 의 출금이 결재 요청되었습니다. <br/>결재가 완료되면 코다가 출금 처리를 시작합니다.",
    description2:
      "<span>{{coin}}</span> 의 출금이 처리 요청되었습니다. <br/>출금 완료까지 <span>1영업일이 소요</span> 됩니다.",
    check1: "내가 올린 결재 확인하기",
    check2: "처리 상태 확인하기",
  },
  registerAddressBookCompleteDialog: {
    title: "주소록 등록 완료",
    description: "주소록이 등록 되었습니다.",
  },
  deleteAddressBookCompleteDialog: {
    title: "주소록 삭제 완료",
    description: "주소록이 삭제되었습니다.",
  },
  requestAddressBookCompleteDialog: {
    create: {
      title: "주소록 등록 결재 요청 완료",
      description:
        "주소 등록이 결재 요청되었습니다. \n결재가 완료되면 주소록이 등록됩니다.",
    },
    delete: {
      title: "주소록 삭제 결재 요청 완료",
      description:
        "주소 삭제가 결재 요청되었습니다. \n결재가 완료되면 주소록이 삭제됩니다.",
    },
  },
  deleteAddressBookDialog: {
    title: "주소록을 삭제하시겠습니까?",
    description: "<span>{{addressBook}}</span> 을 삭제합니다.",
  },
  updatePolicyDialog: {
    title: "{{content}} 결재선 변경하기",
    description: {
      walletOwner: "반드시 책임자 결재 필요",
      walletManager: "중간책임자 또는 책임자 결재 필요",
      walletManagerAndOwnerSequentialApproval:
        "중간책임자 결재 후 책임자 결재 필요",
    },
  },
  updatePolicyCompleteDialog: {
    title: "출금 결재선 변경 완료",
    description: "<span>{{name}}</span>로 변경되었습니다.",
  },
  deleteInvitedWalletMemberDialog: {
    title: "구성원 삭제하기",
    description: "다음과 같은 구성원을 {{walletName}} 지갑에서 삭제합니다.",
  },
  deleteInvitedWalletMemberCompleteDialog: {
    title: "삭제 완료",
    description:
      "<span>{{memberName}}</span>을 {{walletName}} 지갑에서 삭제했습니다.",
  },
  updateWalletMemberRoleCompleteDialog: {
    title: "변경 완료",
    description: "<span>{{memberName}}</span>의 역할을 변경했습니다.",
  },
  invitedWalletMemberDialog: {
    title: "구성원 초대하기",
    description: "{{walletName}} 지갑에 초대할 구성원을 선택하세요.",
  },
  invitedWalletMemberCompleteDialog: {
    title: "초대 완료",
    description1: " 외 {{count}}명",
    description2: "을 {{walletName}} 지갑에 초대했습니다.",
  },
  invitedOrganizationMemberDialog: {
    title: "구성원 초대하기",
    description: "초대할 구성원의 이메일을 입력하세요.",
    placeholder: "이메일 입력 후 Enter 키 또는 스페이스바를 눌러주세요.",
  },
  registerWalletInviteInfoDialog: {
    title: "지갑에 다른 구성원을 초대하세요.",
    description:
      "함께 지갑을 관리할 다른 구성원을 초대할 수 있습니다. \n구성원별로 다른 역할을 부여해 안전하게 협업하세요.",
    dontShowAgain: "$t(dontShowAgain)",
    invite: "구성원 초대하기",
  },
  firstPolicyEntryDialog: {
    title: "지갑의 결재선을 설정하세요.",
    description: "출금, 주소록 등록/삭제에 대한 결재선을 설정하세요.",
    confirm: "결재선 설정하기",
  },
  addressBookEmptyStates: {
    title: "등록된 주소가 없어요.",
    description: "주소록에 등록된 주소로만 출금할 수 있습니다.",
    add: "주소 추가하기",
  },
  addressBookDetailDialog: {
    title: "주소록 상세 내역",
    receiverName: "수취인 이름",
    receiverKoName: "수취인 성명(한글)",
    receiverEnName: "수취인 성명(영문)",
  },
  requestReasonDialog: {
    title: "결재 요청 사유를 작성해주세요.",
    description:
      "{{request}}를 진행하려면 <span>{{approvalLine}}</span> 이상의 결재가 필요합니다. <br/>모든 결재가 완료되면 {{request}} 요청됩니다.",
    placeholder: "결재 요청 사유를 작성하세요.",
    addressBook: {
      create: "주소록 추가",
      delete: "주소록 삭제",
    },
  },
  cancelWithdrawDialog: {
    title: "출금을 취소합니다.",
    description: "취소할 출금 정보를 다시 한번 확인해주세요.",
  },
  cancelWithdrawCompleteDialog: {
    title: "출금 취소 완료",
    description: "출금이 취소 되었습니다.",
  },
  cancelInvitedOrganizationMemberCompleteDialog: {
    title: "초대 취소 완료",
    description: "초대가 취소되었습니다.",
  },
  invitedOrganizationMemberCompleteDialog: {
    title: "초대 메일 전송 완료",
    description: "초대 메일이 전송되었습니다.",
  },
  invalidInvitedMemberDialog: {
    title: "유효하지 않은 초대장입니다.",
  },
  setAccountEmailForm: {
    title: "계정 이메일 입력하기",
    description: "코다에 가입했던 계정의 이메일을 입력하세요.",
  },
  requestResetPasswordModal: {
    title: "비밀번호 재설정 요청",
    step3: "계정 복구 이메일 전송",
  },
  requestResetPasswordEmailForm: {
    title: "이메일이 전송되었습니다.",
    description:
      "<span>{{email}}</span>로 비밀번호 재설정 이메일이 전송되었습니다. 메일함을 확인해 주세요.",
  },
  requestStep: {
    request: "요청",
    signing: "결재",
    proceeding: "처리",
    complete: "완료",
  },
  roles: {
    organizationSuperAdmin: "최고 관리자",
    organizationWalletsAdmin: "지갑 관리자",
    organizationUsersAdmin: "구성원 관리자",
    organizationUser: "멤버",
    walletOwner: "지갑 책임자",
    walletManager: "지갑 중간책임자",
    walletMember: "지갑 실무자",
    walletViewer: "지갑 조회자",
    custodyAdmin: "커스터디 관리자",
    custodyManager: "커스터디 매니저",
    custodySigner: "커스터디 서명자",
    custodyViewer: "커스터디 뷰어",
    custodyOperator: "커스터디 운영자",
    custodyAccountEmailViewer: "커스터디 이메일 뷰어",
  },
  walletRoles: {
    walletOwner: "책임자",
    walletManager: "중간책임자",
    walletMember: "실무자",
    walletViewer: "조회자",
  },
  addressBook: {
    label: "주소록",
    create: "주소록 등록",
    delete: "주소록 삭제",
    deleted: "삭제된 주소록",
    error: {
      DUPLICATED_NAME: "같은 지갑 내에 동일한 주소명이 있습니다.",
      DUPLICATED_NAME_REQUESTED:
        "이미 같은 이름의 주소록 등록이 요청된 상태입니다.",
      DUPLICATED_ADDRESS_AND_COIN:
        "같은 지갑 내에 동일한 주소, 코인/토큰으로 등록된 주소록이 있습니다.",
      DUPLICATED_ADDRESS: "이미 같은 주소의 주소록 등록이 요청된 상태입니다.",
      INVALID_ADDRESS: "올바르지 않은 주소입니다.",
    },
  },
  requestStatus: {
    ALL: "모든 상태",
    REQUEST_PENDING: "결재 대기",
    REQUEST_APPROVAL: "결재 완료",
    REQUEST_REJECT: "결재 반려",
    PROCEEDING_PENDING: "처리 대기",
    PROCEEDING: "처리중",
    PROCEEDING_REJECT: "처리 거절",
    PROCEEDING_CANCEL: "처리 취소",
    TRANSFER: "전송중",
    TRANSFER_FAILED: "전송 실패",
    TRANSFER_COMPLETE: "전송 완료",
    DEPOSIT: "입금 확인중",
    DEPOSIT_COMPLETE: "입금 완료",
    CREATING: "생성중",
    CREATING_FAILED: "생성 실패",
    CREATING_COMPLETE: "생성 완료",
  },
  approverSigningStatus: {
    pending: "대기",
    approve: "결재",
    skipApprove: "결재 생략",
    reject: "반려",
  },
};

export default ko;
