import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="url(#paint0_linear_6003_45)"
      />
      <g clipPath="url(#clip0_6003_45)">
        <mask
          id="mask0_6003_45"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16">
          <path d="M19.9964 4.82H4V19.1524H19.9964V4.82Z" fill="white" />
</mask>
<g mask="url(#mask0_6003_45)">
<path d="M19.914 16.12L17.2734 18.9571C17.2159 19.0188 17.1465 19.0679 17.0693 19.1015C16.9922 19.1351 16.9089 19.1524 16.8247 19.1524H4.30657C4.24683 19.1524 4.18841 19.1349 4.13846 19.1021C4.08851 19.0693 4.04922 19.0225 4.02541 18.9677C4.0016 18.9128 3.99431 18.8522 4.00444 18.7932C4.01457 18.7342 4.04167 18.6795 4.08242 18.6357L6.72511 15.7986C6.78236 15.7371 6.85159 15.6881 6.92853 15.6545C7.00546 15.6209 7.08845 15.6035 7.17237 15.6034H19.6899C19.7496 15.6034 19.808 15.6209 19.8579 15.6537C19.9079 15.6865 19.9472 15.7332 19.971 15.7881C19.9948 15.843 20.0021 15.9037 19.992 15.9626C19.9819 16.0216 19.9548 16.0763 19.914 16.12ZM17.2734 10.4069C17.2159 10.3453 17.1465 10.2961 17.0693 10.2626C16.9922 10.229 16.9089 10.2116 16.8247 10.2117H4.30657C4.24683 10.2117 4.18841 10.2292 4.13846 10.262C4.08851 10.2948 4.04922 10.3415 4.02541 10.3964C4.0016 10.4513 3.99431 10.5119 4.00444 10.5709C4.01457 10.6299 4.04167 10.6846 4.08242 10.7283L6.72511 13.5655C6.78236 13.627 6.85159 13.676 6.92853 13.7096C7.00546 13.7431 7.08845 13.7605 7.17237 13.7607H19.6899C19.7496 13.7607 19.808 13.7432 19.8579 13.7104C19.9079 13.6776 19.9472 13.6308 19.971 13.5759C19.9948 13.5211 20.0021 13.4604 19.992 13.4015C19.9819 13.3425 19.9548 13.2878 19.914 13.244L17.2734 10.4069ZM4.30657 8.36897H16.8247C16.9089 8.36901 16.9922 8.35171 17.0693 8.31812C17.1465 8.28454 17.2159 8.23541 17.2734 8.17377L19.914 5.33665C19.9548 5.2929 19.9819 5.23818 19.992 5.1792C20.0021 5.12023 19.9948 5.05958 19.971 5.00471C19.9472 4.94983 19.9079 4.90311 19.8579 4.8703C19.808 4.83748 19.7496 4.82 19.6899 4.82H7.17237C7.08845 4.82014 7.00546 4.83754 6.92853 4.87112C6.85159 4.90469 6.78236 4.95373 6.72511 5.01519L4.08311 7.85232C4.0424 7.89603 4.0153 7.95068 4.00515 8.00959C3.995 8.0685 4.00223 8.1291 4.02595 8.18395C4.04968 8.2388 4.08886 8.28552 4.13871 8.31839C4.18856 8.35126 4.24689 8.36883 4.30657 8.36897Z" fill="white"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_6003_45" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
<stop stop-color="#9945FF"/>
<stop offset="1" stop-color="#14F195"/>
</linearGradient>
<clipPath id="clip0_6003_45">
<rect width="16" height="14.3642" fill="white" transform="translate(4 4.82)"/>
</clipPath>
</defs>
</svg>

  );
}

export default SvgComponent;
