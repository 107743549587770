import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_7134_915)">
        <path
          d="M11.8957 21.8259C17.38 21.8259 21.8259 17.3877 21.8259 11.913C21.8259 6.43818 17.38 2 11.8957 2C6.69247 2 2.42393 5.99485 2 11.0797H15.1255V12.7462H2C2.42393 17.8311 6.69247 21.8259 11.8957 21.8259Z"
          fill="#0A0B0D"
        />
      </g>
      <defs>
        <clipPath id="clip0_7134_915">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
