import _ from "lodash";
import { Instance, flow, types } from "mobx-state-tree";
import { Blockchain, SupportedBlockchainDto } from "src/__generate__";
import { getSupportedBlockchains } from "src/apis/blockchains";
import { orderByBlockchain } from "src/utils/array";
import { handleBlockchainName } from "src/utils/string";

const sortABC = (list: SupportedBlockchainDto[]) =>
  _.orderBy(
    list?.map((x) => ({
      ...x,
      blockchainName: handleBlockchainName(x.blockchain!),
    })),
  );

const Blockchains = types
  .model("Blockchains", {
    data: types.optional(
      types.array(types.frozen<SupportedBlockchainDto>()),
      [],
    ),
  })
  .views((self) => ({
    get blockchains() {
      return orderByBlockchain(Array.from(self.data.values()), sortABC).map(
        (x) => x?.blockchain as Blockchain,
      );
    },
    get nonDeprecatedBlockchains() {
      return orderByBlockchain(Array.from(self.data.values()), sortABC)
        .filter((x) => !x?.isDeprecated)
        .map((x) => x?.blockchain as Blockchain);
    },
  }))
  .actions((self) => {
    const fetch = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getSupportedBlockchains> =
        yield getSupportedBlockchains();

      self.data.replace(response);
    });

    const checkV2Chain = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.version === 2,
        ),
      );
    };

    const checkTestnet = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.isTestnet,
        ),
      );
    };

    const checkDeprecated = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.isDeprecated,
        ),
      );
    };

    const checkEos = (blockchain: Blockchain) => {
      return blockchain === Blockchain.Bizauto;
    };

    const checkSolana = (blockchain: Blockchain) => {
      return blockchain === Blockchain.Solana;
    };

    const getBlockchain = (blockchain: Blockchain) => {
      return self.data.find((item) => item.blockchain === blockchain);
    };

    return {
      fetch,
      checkV2Chain,
      checkTestnet,
      checkDeprecated,
      checkEos,
      checkSolana,
      getBlockchain,
    };
  });

export type IBlockchains = Instance<typeof Blockchains>;
export default Blockchains;
