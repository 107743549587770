import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#0052FF"
      />
      <g clip-path="url(#clip0_7134_1221)">
        <path
          d="M11.9165 19.8608C16.304 19.8608 19.8608 16.3102 19.8608 11.9304C19.8608 7.55054 16.304 4 11.9165 4C7.75398 4 4.33914 7.19588 4 11.2638H14.5004V12.597H4C4.33914 16.6649 7.75398 19.8608 11.9165 19.8608Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7134_1221">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
