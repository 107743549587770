import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7283 4.07437L4.87174 8.03717V15.9628L11.7283 19.9256L18.5849 15.9628V8.03717L11.7283 4.07437ZM12.0163 2.07724C11.8381 1.97425 11.6185 1.97425 11.4404 2.07724L3.28794 6.78897C3.10975 6.89197 3 7.08231 3 7.28827V16.7117C3 16.9177 3.10975 17.108 3.28794 17.211L11.4404 21.9228C11.6185 22.0258 11.8381 22.0258 12.0163 21.9228L20.1687 17.211C20.3469 17.108 20.4566 16.9177 20.4566 16.7117V7.28827C20.4566 7.08231 20.3469 6.89197 20.1687 6.78897L12.0163 2.07724Z"
        fill="#121212"
      />
      <path
        d="M11.7282 6.94505L7.35509 9.47251V14.5275L11.6994 17.0383C11.7172 17.0486 11.7282 17.0676 11.7282 17.0882V17.9537C11.7282 17.998 11.6802 18.0258 11.6418 18.0036L6.7072 15.1515C6.57356 15.0743 6.49121 14.9316 6.49121 14.7771V9.22286C6.49121 9.06838 6.57356 8.92565 6.7072 8.84839L11.5122 6.07128C11.6459 5.99406 11.8105 5.99406 11.9442 6.07128L16.8985 8.92434C16.9366 8.94626 16.9369 9.00115 16.8992 9.02361C16.6068 9.19721 16.4571 9.27443 16.13 9.45654C16.1123 9.46639 16.0906 9.4663 16.073 9.45618L11.7282 6.94505Z"
        fill="#121212"
      />
      <path
        d="M12.4485 12.1066C12.4485 12.0853 12.4602 12.0658 12.479 12.0557L16.8272 9.73895C16.8656 9.71852 16.9119 9.74633 16.9119 9.78985V15.4259C16.9119 15.4465 16.9009 15.4655 16.8832 15.4758L12.535 18.0032C12.4966 18.0255 12.4485 17.9978 12.4485 17.9533V12.1066Z"
        fill="#121212"
      />
    </svg>
  );
}

export default SvgComponent;
