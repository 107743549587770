import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6003_30)">
<mask id="mask0_6003_30" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="2" y="3" width="20" height="18">
<path d="M21.9955 3.02234H2V20.9378H21.9955V3.02234Z" fill="white"/>
</mask>
<g mask="url(#mask0_6003_30)">
<path d="M21.8925 17.1474L18.5917 20.6938C18.5199 20.7708 18.4331 20.8322 18.3367 20.8742C18.2402 20.9162 18.1361 20.9379 18.0309 20.9378H2.38321C2.30854 20.9378 2.23551 20.9159 2.17307 20.8749C2.11063 20.8339 2.06152 20.7755 2.03176 20.7069C2.002 20.6383 1.99289 20.5625 2.00555 20.4888C2.01821 20.4151 2.05209 20.3467 2.10303 20.292L5.40638 16.7456C5.47795 16.6687 5.56449 16.6074 5.66066 16.5655C5.75682 16.5235 5.86056 16.5018 5.96546 16.5016H21.6123C21.687 16.5016 21.76 16.5234 21.8224 16.5644C21.8849 16.6055 21.934 16.6639 21.9638 16.7325C21.9935 16.8011 22.0026 16.8769 21.99 16.9506C21.9773 17.0243 21.9435 17.0927 21.8925 17.1474ZM18.5917 10.006C18.5199 9.92892 18.4331 9.86751 18.3367 9.82553C18.2402 9.78355 18.1361 9.76189 18.0309 9.76195H2.38321C2.30854 9.76195 2.23551 9.78381 2.17307 9.82483C2.11063 9.86585 2.06152 9.92425 2.03176 9.99282C2.002 10.0614 1.99289 10.1372 2.00555 10.211C2.01821 10.2847 2.05209 10.3531 2.10303 10.4078L5.40638 13.9542C5.47795 14.031 5.56449 14.0923 5.66066 14.1343C5.75682 14.1763 5.86056 14.198 5.96546 14.1982H21.6123C21.687 14.1982 21.76 14.1763 21.8224 14.1353C21.8849 14.0943 21.934 14.0359 21.9638 13.9673C21.9935 13.8987 22.0026 13.8228 21.99 13.7492C21.9773 13.6754 21.9435 13.6071 21.8925 13.5524L18.5917 10.006ZM2.38321 7.45855H18.0309C18.1361 7.4586 18.2402 7.43697 18.3367 7.39499C18.4331 7.35302 18.5199 7.2916 18.5917 7.21456L21.8925 3.66815C21.9435 3.61346 21.9773 3.54506 21.99 3.47135C22.0026 3.39763 21.9935 3.32182 21.9638 3.25322C21.934 3.18462 21.8849 3.12623 21.8224 3.08521C21.76 3.04419 21.687 3.02234 21.6123 3.02234H5.96546C5.86056 3.02252 5.75682 3.04426 5.66066 3.08623C5.56449 3.1282 5.47795 3.1895 5.40638 3.26633L2.10388 6.81273C2.05299 6.86737 2.01913 6.93569 2.00644 7.00933C1.99375 7.08296 2.00278 7.15871 2.03244 7.22728C2.0621 7.29584 2.11108 7.35424 2.17339 7.39532C2.2357 7.43641 2.30862 7.45838 2.38321 7.45855Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_6003_30">
<rect width="20" height="17.9553" fill="white" transform="translate(2 3.02234)"/>
</clipPath>
</defs>
</svg>
);

export default SvgComponent;
